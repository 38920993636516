/* eslint-disable import/no-anonymous-default-export */
import { GET_TRANSACTIONS_START, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_FAIL } from "../Actions/Types";
 
 const initialState = {
     response: '',
     error: '',
     getTransactionsLoading: false,
   };
   
   export default function (state = initialState, action) {
     const { type, payload } = action;
     switch (type) {
         case GET_TRANSACTIONS_START:
           return {
             ...state,
             getTransactionsLoading: true
           };
         case GET_TRANSACTIONS_SUCCESS:
         return {
           ...state,
           response: payload,
           getTransactionsLoading: false
         };
         case GET_TRANSACTIONS_FAIL:
         return {
           ...state,
           error: payload,
           getTransactionsLoading: false
         }; 
         default:
           return state;
     }
 }
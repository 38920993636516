import {
  ADD_PACKAGE_FAIL,
  ADD_PACKAGE_START,
  ADD_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  DELETE_PACKAGE_START,
  DELETE_PACKAGE_SUCCESS,
  EDIT_PACKAGE_FAIL,
  EDIT_PACKAGE_START,
  EDIT_PACKAGE_SUCCESS,
  GET_PACKAGE_FAIL,
  GET_PACKAGE_START,
  GET_PACKAGE_SUCCESS,
  SEND_PLAN_CHANGE_EMAIL_START,
  SEND_PLAN_CHANGE_EMAIL_SUCCESS,
  SEND_PLAN_CHANGE_EMAIL_FAIL,
} from "./Types";
import api from "../Api/Api";
import { alertActions } from "./alertActions";

//Update Trainer Social Handles
export const addPackageManager = (body) => async (dispatch) => {
  dispatch({
    type: ADD_PACKAGE_START,
  });
  try {
    const res = await api.post("/package/addPackage", body);
    if (res) {
      // console.log("res", res);
      dispatch({
        type: ADD_PACKAGE_SUCCESS,
        payload: res.data.msg,
      });
      dispatch(alertActions.success(res.data.msg));
      setTimeout(() => {
        dispatch(alertActions.success_clear());
        dispatch(alertActions.clear());
      }, 3000);
    }
  } catch (err) {
    console.log("error", err);
    dispatch({
      type: ADD_PACKAGE_FAIL,
      payload: err.response.data,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

export const getPackageManager = () => async (dispatch) => {
  dispatch({
    type: GET_PACKAGE_START,
  });
  try {
    const res = await api.get("/package/getPackage");
    if (res) {
      // console.log("res", res);
      dispatch({
        type: GET_PACKAGE_SUCCESS,
        payload: res.data && res.data.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PACKAGE_FAIL,
      payload: err.response.data,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

export const deletePackageManager = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PACKAGE_START,
    });
    const res = await api.delete(`/package/deletePackage/${id}`);
    if (res) {
      dispatch({
        type: DELETE_PACKAGE_SUCCESS,
        payload: res.data.msg,
      });
      dispatch(alertActions.success(res.data.msg));
      setTimeout(() => {
        dispatch(alertActions.success_clear());
        dispatch(alertActions.clear());
      }, 3000);
    }
  } catch (err) {
    dispatch({
      type: DELETE_PACKAGE_FAIL,
      payload: err.response.data.msg,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

export const editPackageManager = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PACKAGE_START,
    });
    const res = await api.put(`/package/editPackage/${id}`, data);
    if (res) {
      // console.log("edit res", res);
      dispatch({
        type: EDIT_PACKAGE_SUCCESS,
        payload: res.data.msg,
      });
      dispatch(alertActions.success(res.data.msg));
      setTimeout(() => {
        dispatch(alertActions.success_clear());
        dispatch(alertActions.clear());
      }, 3000);
    }
  } catch (err) {
    console.log("error", err);
    dispatch({
      type: EDIT_PACKAGE_FAIL,
      payload: err.response.data.msg,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

export const send_email_plan_change = (data) => async (dispatch) => {
  dispatch({
    type: SEND_PLAN_CHANGE_EMAIL_START,
  });
  console.log(data);
  try {
    const res = await api.get("/package/change-plan/" + data.id, data.data);
    if (res) {
      // console.log("res", res);
      dispatch({
        type: SEND_PLAN_CHANGE_EMAIL_SUCCESS,
        payload: res.data && res.data.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEND_PLAN_CHANGE_EMAIL_FAIL,
      payload: err.response,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

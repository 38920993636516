import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import usePagination from "../Common/Pagination";
import { dateFormatUTCtoddmmyyyy } from '../Common/date';
import { Pagination } from "@mui/material";
import { getSubscriptions } from '../../Redux/Actions/subscriptionsActions';

import styles from './Subscriptions.module.css';


function Subscriptions() {

  let subscriptions = []
  const per_page = 10;

    //Redux Dispatch
  const dispatch = useDispatch();

  //Redux Selector to get store data
  const subscriptionsResponse = useSelector((state) => state.subscriptionsReducer);

  if(subscriptionsResponse.response.data && Array.isArray(subscriptionsResponse.response.data)) {
    subscriptions = [...subscriptionsResponse.response.data].reverse();
  } else {
    subscriptions = [];
  }

  const paginatedData = usePagination(subscriptions, per_page);

  const paginationHandler = (event, value) => {
    paginatedData.jump(value);
  };

  // UseEffects (start):
  useEffect(() => {
    dispatch(getSubscriptions());
  }, [dispatch]);
  // UseEffects (end):

  return (
    <Fragment>
      <div className="outerPage">
        <div>
          <h4>Subscriptions</h4>
        </div>
        <div className="innerPage p-2">
            <div className={`${styles.tableContainer}`}>
            <table className={`my-4`}>
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th>Business Name</th>
                  <th>Package Name</th>
                  <th>Subscriber</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Subscription Status</th>
                </tr>
              </thead>
              <tbody>
                {!subscriptionsResponse.getSubscriptionsLoading && paginatedData.currentData().map((item, index) =>
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.business.business_name}</td>
                        <td>{item.package.package_name}</td>
                        <td>{item.user.first_name}&nbsp;{item.user.last_name}</td>
                        <td>{dateFormatUTCtoddmmyyyy(item.subscription.payment_date)}</td>
                        <td>{dateFormatUTCtoddmmyyyy(item.subscription.end_date)}</td>
                        <td>{Number(item.subscription.status) === 1 ? "Active" : Number(item.subscription.status) === 2 ? "Expired" : "Stop"}</td>
                        {/* <td>{row.status}</td> */}
                    </tr> 
                )}
                  
              </tbody>
            </table>
            {subscriptionsResponse.getSubscriptionsLoading  && <div className="text-center my-5">
              <span className="spinner-border table-spinner" role="status"></span>
            </div>}
            {!subscriptionsResponse.getSubscriptionsLoading ? subscriptions.length > 0 ? null : (
              <p className="text-center h5 mt-5">No Data Available</p>
              ) : null}
              { !subscriptionsResponse.getSubscriptionsLoading  && subscriptions.length > 0 &&
              <div className="d-flex justify-content-center">
                <Pagination
                  count={paginatedData.maxPage}
                  page={paginatedData.currentPage}
                  color="secondary"
                  shape="circular"
                  variant="outlined"
                  default={paginatedData.currentPage}
                  onChange={paginationHandler}
                />
            </div>}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Subscriptions;
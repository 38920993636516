import React, { Fragment, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinesses,
  getDashboardCount,
} from "../../Redux/Actions/businessActions";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

// import styles from './Dashboard.module.css';

function Dashboard() {
  const businessesResponse = useSelector((state) => state.businessReducer);
  // console.log(businessesResponse);
  const [business, setBusiness] = useState([]);
  const [response, setResponse] = useState("");
  const [userCount, setUsersCount] = useState(0);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [channelCount, setChannelCount] = useState(0);
  const [totalChannelCount, setTotalChannelCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [totalProjectCount, setTotalProjectCount] = useState(0);
  const dispatch = useDispatch();

  // UseEffects (start):
  useEffect(() => {
    dispatch(getBusinesses());
  }, [dispatch]);

  useEffect(() => {
    if (businessesResponse) {
      if (businessesResponse.response) {
        if (businessesResponse.response.data) {
          setBusiness(businessesResponse.response.data);
        }
      }
    }
  }, [businessesResponse]);

  useEffect(() =>{
    if(response && businessesResponse){
      if (businessesResponse.getDashboardCount) {
        if (businessesResponse.getDashboardCount.data) {
          setUsersCount(businessesResponse.getDashboardCount.data.usercount);
          setChannelCount(businessesResponse.getDashboardCount.datachannelcount);
          setProjectCount(businessesResponse.getDashboardCount.data.projectcount);
          if (businessesResponse.getDashboardCount.data.packagePropertyData) {
            for (var i = 0; i < businessesResponse.getDashboardCount.data.packagePropertyData.length; i++
            ) {
              if ( businessesResponse.getDashboardCount.data.packagePropertyData[i].property_name === "Users") {
                setTotalUserCount(
                 Number (businessesResponse.getDashboardCount.data.packagePropertyData[i].property_value)
                );
              }
              if (
                businessesResponse.getDashboardCount.data.packagePropertyData[i]
                  .property_name === "Channel"
              ) {
                setTotalChannelCount(
                  businessesResponse.getDashboardCount.data.packagePropertyData[i]
                    .property_value
                );
              }
              if (
                businessesResponse.getDashboardCount.data.packagePropertyData[i]
                  .property_name === "Projects"
              ) {
                setTotalProjectCount(
                  businessesResponse.getDashboardCount.data.packagePropertyData[i]
                    .property_value
                );
              }
            }
          }
        }
      }
    }
    //console.log(totalUserCount,totalChannelCount,totalProjectCount)
  },[response, businessesResponse])

  const submit = () => {
    if (response) {
      dispatch(getDashboardCount(response));
      dispatch(getBusinesses());
    }
  };

  const data1 = {
    labels: ["Users Count", "Total Count"],
    datasets: [
      {
        label: "# of Users",
        data: [userCount,totalUserCount],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgb(211, 211, 211)",
          // "rgba(54, 162, 235, 0.2)",
          // "rgba(255, 206, 86, 0.2)",
          // "rgba(75, 192, 192, 0.2)",
          // "rgba(153, 102, 255, 0.2)",
          // "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgb(124,124,124)",
          // "rgba(54, 162, 235, 1)",
          // "rgba(255, 206, 86, 1)",
          // "rgba(75, 192, 192, 1)",
          // "rgba(153, 102, 255, 1)",
          // "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const data2 = {
    labels: ["Channel Count", "Total Count"],
    datasets: [
      {
        label: "# of Channel",
        data: [channelCount,totalChannelCount],
        backgroundColor: [
          "rgba(153, 102, 255, 0.2)",
          "rgb(211, 211, 211)",
          // "rgba(54, 162, 235, 0.2)",
          // "rgba(255, 206, 86, 0.2)",
          // "rgba(75, 192, 192, 0.2)",
          // "rgba(153, 102, 255, 0.2)",
          // "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(153, 102, 255, 1)",
          "rgb(124,124,124)",
          // "rgba(54, 162, 235, 1)",
          // "rgba(255, 206, 86, 1)",
          // "rgba(75, 192, 192, 1)",
          // "rgba(153, 102, 255, 1)",
          // "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const data3 = {
    labels: ["Project Count", "Total Count"],
    datasets: [
      {
        label: "# of Projects",
        data: [projectCount,totalProjectCount],
        backgroundColor: [
          "rgba(255, 206, 86, 0.2)",
          "rgb(211, 211, 211)",
          // "rgba(54, 162, 235, 0.2)",
          // "rgba(255, 206, 86, 0.2)",
          // "rgba(75, 192, 192, 0.2)",
          // "rgba(153, 102, 255, 0.2)",
          // "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 206, 86, 1)",
          "rgb(124,124,124)",
          // "rgba(54, 162, 235, 1)",
          // "rgba(255, 206, 86, 1)",
          // "rgba(75, 192, 192, 1)",
          // "rgba(153, 102, 255, 1)",
          // "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Fragment>
      <div className="outerPage">
        <div>
          <h4>Dashboard</h4>
        </div>
        <div className="innerPage1 p-2">
          <h5>Welcome to dashboard page</h5>

          <div className="col-md-6 mt-5 row">
            <div className="col-md">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setResponse(e.target.value);
                }}
              >
                <option>Select Business</option>
                {business.length > 0 &&
                  business.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.business_name}
                    </option>
                  ))}
              </Form.Select>
            </div>
            <div className="col-md">
              <button className="btn fillBtn" onClick={submit}>
                Submit
              </button>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <Doughnut data={data1} />;
            </div>
            <div className="col-md-4">
              <Doughnut data={data2} />;
            </div>
            <div className="col-md-4">
              <Doughnut data={data3} />;
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Dashboard;

import React, { Fragment, useEffect, useState } from "react";
import PackageModal from "../PackageModal/PackageModal";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { Pagination } from "@mui/material";
import usePagination from "../Common/Pagination";
import classes from "./PackageManagement.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePackageManager,
  getPackageManager,
} from "../../Redux/Actions/packageAction";

function PackageManagement() {

  let packageData = [];
  const per_page = 5;

  const dispatch = useDispatch();

  const packageResponse = useSelector((state) => state.packageManager);
  const alert = useSelector((state) => state.alert);


  if (packageResponse.getPackageResponse && Array.isArray(packageResponse.getPackageResponse)) {
    packageData = packageResponse.getPackageResponse;
  } else {
    packageData = [];
  }

  const paginatedData = usePagination(packageData, per_page);

  const paginationHandler = (event, value) => {
    paginatedData.jump(value);
  };

  const initialModalValues = {
    package_name: "",
    package_price: "",
    package_property: [
      {
        property_name: "",
        property_value: "",
      },
    ],
    description: "",
  };

  const [showAddPackageModal, setShowAddPackageModal] = useState({
    show: false,
    data: { ...initialModalValues },
  });
  const [showEditPackageModal, setShowEditPackageModal] = useState({
    show: false,
    data: { ...initialModalValues },
  });

  const handleClickOpen = () => {
    setShowAddPackageModal((prev) => {
      return { ...prev, show: true };
    });
  };

  const handleClose = () => {
    setShowAddPackageModal((prev) => {
      return { ...prev, show: false };
    });
    setShowEditPackageModal((prev) => {
      return { ...prev, show: false };
    });
  };

  const editRowHandler = (rowData) => {
    setShowEditPackageModal({ show: true, data: { ...rowData } });
  };

  const deleteRowHandler = (id) => {
    dispatch(deletePackageManager(id));
  };

  useEffect(() => {
    dispatch(getPackageManager());
    // eslint-disable-next-line
  }, [alert]);

  return (
    <Fragment>
      <div className="outerPage">
        <div>
          <h4>Package Management</h4>
        </div>
        <div className="innerPage p-2">
          <div className="m-2 ps-2">
            <button className={`btn fillBtn`} onClick={handleClickOpen}>
              Add Package
            </button>
          </div>
          <PackageModal
            openPackage={showAddPackageModal}
            handleClose={handleClose}
            editPackage={showEditPackageModal}
          />
          <div className={`${classes.tableContainer}`}>
            <table className={`my-4`}>
              <thead>
                <tr>
                  <th>SL No:</th>
                  <th>Package Name</th>
                  <th>Package Price</th>
                  <th>Description</th>
                  <th>Validity(days)</th>
                  <th>Properties</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!packageResponse.getLoading &&
                  paginatedData.currentData().map((row, index) => (
                      <tr key={row.package.id}>
                        <td>{index+1}</td>
                        <td>{row.package.package_name} </td>
                        <td className="text-start" style={{width:"18%"}}>{row.packageType.map((item, index) => {
                              return <li key={item.id}>{item.package_type === 1 ? "Yearly" : "Monthly" }&nbsp;-&nbsp;{item.price}</li>
                            })}</td>
                        <td>{row.package.description}</td>
                        <td className="text-start" style={{width:"18%"}}>
                        {row.packageType.map((item, index) => {
                              return <li key={item.id}>{item.package_type === 1 ? "Yearly" : "Monthly" }&nbsp;-&nbsp;{item.validity}</li>
                            })}
                        </td>
                        <td className="text-start" style={{width:"18%"}}>
                          <ul>
                            {row.properties.map((item, index) => {
                              return <li key={item.id}>{item.property_name}&nbsp;-&nbsp;{item.property_value}</li>
                            })}
                        </ul>
                        </td>
                        <td>
                          <div className={`${classes.actionCell}`}>
                            <span className="me-1">
                              <button
                                type="button"
                                onClick={() => {
                                  editRowHandler(row);
                                }}
                                className={`btn ${classes.actionEditBtn}`}
                              >
                                <BorderColorIcon fontSize="small"/>
                              </button>
                            </span>
                            <span className="me-1">
                              <button
                                type="button"
                                onClick={() => {
                                  deleteRowHandler(row.package.id);
                                }}
                                className={`btn ${classes.actionDelBtn}`}
                              >
                                <DeleteTwoToneIcon fontSize="small"/>
                              </button>
                            </span>
                          </div>
                        </td>
                      </tr>
                  ))}
              </tbody>
            </table>
            {packageResponse.getLoading && <div className="text-center my-5">
                <span className="spinner-border table-spinner" role="status"></span>
            </div>}
            {!packageResponse.getLoading ? packageData.length > 0 ? null : (
                <p className="text-center h5 mt-5">No Data Available</p>
              ) : null}
            {!packageResponse.getLoading  && packageData.length > 0 && 
            <div className="d-flex justify-content-center">
              <Pagination
                count={paginatedData.maxPage}
                page={paginatedData.currentPage}
                color="secondary"
                shape="circular"
                variant="outlined"
                default={paginatedData.currentPage}
                onChange={paginationHandler}
              />
            </div>}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PackageManagement;

import React, { Fragment, useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Transactions.module.css";
import { getTransactions } from "../../Redux/Actions/transactionsActions";
import usePagination from "../Common/Pagination";
import { dateFormatUTCtoddmmyyyy } from "../Common/date";
import { Pagination } from "@mui/material";

function Transactions() {

  let transactions = []
  const per_page = 10;

  //Redux Dispatch
  const dispatch = useDispatch();

  //Redux Selector to get store data
  const transactionsResponse = useSelector((state) => state.transactionsReducer);

  if(transactionsResponse.response.data && Array.isArray(transactionsResponse.response.data)) {
    transactions = [...transactionsResponse.response.data].reverse();
  } else {
    transactions = [];
  }

  const paginatedData = usePagination(transactions, per_page);

  const paginationHandler = (event, value) => {
    paginatedData.jump(value);
  };

  // UseEffects (start):
  useEffect(() => {
    dispatch(getTransactions());
  }, [dispatch]);

  // UseEffects (end):

  return (
    <Fragment>
      <div className="outerPage">
        <div>
          <h4>Transactions</h4>
        </div>
        <div className="innerPage p-2">
          <div className={`${styles.tableContainer}`}>
            <table className={`my-4`}>
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th>Transaction Id</th>
                  <th>Admin Name</th>
                  <th>Business Name</th>
                  <th>Package</th>
                  <th>Price</th>
                  <th>Date Of Payment</th>
                  {/* <th>Status</th> */}
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                { !transactionsResponse.getTransactionsLoading && paginatedData.currentData().map((item, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.trasaction_id}</td>
                    <td>{item.userInfo[0].first_name}&nbsp;{item.userInfo[0].last_name}</td>
                    <td>{item.business[0].business_name}</td>
                    <td>{item.package.length>0 ? item.package[0].package_name : "NA"}</td>
                    <td>
                      {((item.currency === null && item.amount === null) || item.amount === 0) ? (item.packageType.length > 0 ? '$ '+item.packageType[0].price : "NA") : (item.curreny !== null && item.amount !== 0) ? item.amount : "NA"}
                      </td>
                    <td>{dateFormatUTCtoddmmyyyy(item.payment_date)}</td>
                    {/* <td>{row.status}</td> */}
                    <td>
                      <div className={`${styles.actionCell}`}>
                        <a href={item.invoice_pdf} className={styles.downloadBtn}>
                          <DownloadIcon />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {transactionsResponse.getTransactionsLoading && <div className="text-center my-5">
              <span className="spinner-border table-spinner" role="status"></span>
            </div>}
            {!transactionsResponse.getTransactionsLoading ? transactions.length > 0 ? null : (
              <p className="text-center h5 mt-5">No Data Available</p>
              ) : null}
              { !transactionsResponse.getTransactionsLoading  && transactions.length > 0 &&
              <div className="d-flex justify-content-center">
                <Pagination
                  count={paginatedData.maxPage}
                  page={paginatedData.currentPage}
                  color="secondary"
                  shape="circular"
                  variant="outlined"
                  default={paginatedData.currentPage}
                  onChange={paginationHandler}
                />
            </div>}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Transactions;

import { Route, Routes } from "react-router-dom";
import Login from "../Components/Login/Login";
import BusinessManagement from "../Components/BusinessManagement/BusinessManagement";
import PackageManagement from "../Components/PackageManagement/PackageManagement";
import Transactions from "../Components/Transactions/Transactions";
import Dashboard from "../Components/Dashboard/Dashboard";
import Sidepanel from "../Components/Sidepanel/Sidepanel";
import Subscriptions from "../Components/Subscriptions/Subscriptions";
import Payment from "../Components/Payment";
import ChangePlan from "../Components/ChangePlan/ChangePlan";
import PlanForm from "../Components/ChangePlan/PlanForm";
import Chat from "../Components/Chat/Chat";

const BaseRoutes = () => {
  return (
    <>
      <Routes mode="absolute">
        <Route path="/" element={<Login />} />
        <Route element={<Sidepanel />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/business" element={<BusinessManagement />} />
          <Route path="/package" element={<PackageManagement />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/chats/:id" element={<Chat />} />
        </Route>
        <Route path="/payment" element={<Payment />} />
        <Route path="/change-plan" element={<ChangePlan />} />
        <Route path="/plan-form/:id" element={<PlanForm />} />
      </Routes>
    </>
  );
};

export default BaseRoutes;

import React from "react";
import Button from "@mui/material/Button";
import { Formik, Form, Field } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "react-bootstrap";
import { businessModalErrors } from "../Components/Common/Constants";
import styles from "../Components/BusinessModal/BusinessModal.module.css";
import { useSelector, useDispatch } from "react-redux";
import { editBusinessPackage } from "../../src/Redux/Actions/businessActions";

const EditPayment = (props) => {
  console.log(props);
  let packages = [];
  const dispatch = useDispatch();
  const packageResponse = useSelector((state) => state.packageManager);
  const { showPayment, closeModal } = { ...props };
  const planOptions = [
    { id: 1, label: "Yearly", value: 1 },
    { id: 2, label: "Monthly", value: 2 },
  ];

  const formValues = {
    package_id: "",
    plan: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.package_id) {
      errors.package_id = businessModalErrors.PACKAGE;
    }
    if (!values.plan) {
      errors.plan = businessModalErrors.PLAN;
    }
    return errors;
  };

  const handleSubmit = (modalData) => {
    console.log(modalData);
    if (showPayment) {
      if (showPayment.data) {
        var obj = {
          id: showPayment.data.id,
          package_id: Number(modalData.package_id),
          plan: Number(modalData.plan),
          transaction_id : props.showPayment.data.transaction_id
        };
      }
    }
    dispatch(editBusinessPackage(obj));
    closeModal();
  };

  if (packageResponse.getPackageResponse) {
    packages = packageResponse.getPackageResponse;
  } else {
    packages = [];
  }

  return (
    <>
      <Modal
        show={showPayment.show}
        onHide={closeModal}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header
          style={{
            background: "#eff0f3",
            color: "var(--color-grey-0)",
            fontWeight: "500",
            fontSize: "20px",
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-10px",
            height:"49px",
            borderBottom: "1px solid #e9ebf0"
          }}
          className="mb-2"
        >
          Edit Payment
          <CloseIcon
            onClick={closeModal}
            sx={{ cursor: "pointer", display: { xs: "none", md: "block" } }}
          />
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={formValues}
            validate={(values) => validate(values)}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className={`mb-4 ${styles.container}`}>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="modal-labels" htmlFor="package_id">
                        Package:<span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        id="package_id"
                        name="package_id"
                        className={
                          touched.package_id && errors.package_id
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      >
                        <option defaultValue="" value="" className="my-2">
                          Select the Package
                        </option>
                        {packages.map((item, index) => (
                          <option
                            key={item.package.id}
                            value={item.package.id}
                            className="my-2"
                          >
                            {item.package.package_name}
                          </option>
                        ))}
                      </Field>
                      {errors.package_id && touched.package_id && (
                        <div className="invalid-feedback">
                          {errors.package_id}
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="modal-labels" htmlFor="package_id">
                        Plan:<span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        id="plan"
                        name="plan"
                        className={
                          touched.plan && errors.plan
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      >
                        <option defaultValue="" value="" className="my-2">
                          Select the plan
                        </option>
                        {planOptions.map((item, index) => (
                          <option
                            key={item.id}
                            value={item.value}
                            className="my-2"
                          >
                            {item.label}
                          </option>
                        ))}
                      </Field>
                      {errors.plan && touched.plan && (
                        <div className="invalid-feedback">{errors.plan}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`d-flex justify-content-end mt-4 mb-3 ${styles.container}`}
                >
                  <Button
                    className="btn outLineBtn"
                    type="button"
                    variant="outlined"
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <button
                    className={`btn fillBtn ms-3`}
                    variant="filled"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditPayment;

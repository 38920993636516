import React, { useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import LogoutIcon from "@mui/icons-material/Logout";
import userImg from "../../Assets/Images/admin.png";
import logo from "../../Assets/Images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/Actions/loginActions";

import "./Sidepanel.css";

export const Sidepanel = (props) => {
  //State Hook:
  const [activeToggle, setActiveToggle] = useState(false);

  // const [activeNav, setActiveNav] = useState(false);

   //Redux Dispatch
   const dispatch = useDispatch();

   //Redux State:
   const loginDetails= useSelector((state) => state.loginReducer);
   // console.log(loginDetails);
 
   if (loginDetails.isAuthenticated === false || loginDetails.role_id === null) {
     return <Navigate to="/" />;
   }

   const logoutHandler = () => {
    console.log("sig")
    dispatch(logout);
  }


  return (
    <div className="main">
      <div className="wrapper">
        <div
          className={
              "sidebarClose d-flex flex-column"
              // : "sidebarOpen d-flex flex-column"
          }
        >
          {/* logo */}
          <div className="app-logo">
            <NavLink
              to="/dashboard"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {activeToggle ? (
                <img className="logo" src={logo} alt="logo" />
              ) : (
                <h3 className="brandName">Multi Tier</h3>
              )}
            </NavLink>
          </div>

          {/* sidebar links */}
          <div>
            <NavLink
              to="/dashboard"
              style={{ textDecoration: "none", color: "inherit" }}
              className={({ isActive }) => (isActive ? "activeLink" : null)}
            >
              <div className="sidebar-links">
                <DashboardIcon
                  className="sidePanelIcons"
                  sx={{ marginRight: "10px" }}
                />
                <span className="sidebar-links-name">Dashboard</span>
              </div>
            </NavLink>
          </div>

          <div>
            <NavLink
              to="/package"
              style={{ textDecoration: "none", color: "inherit" }}
              className={({ isActive }) => (isActive ? "activeLink" : null)}
            >
              <div className="sidebar-links">
                <AddCardOutlinedIcon
                  className="sidePanelIcons"
                  sx={{ marginRight: "10px" }}
                />
                <span className="sidebar-links-name">Package Management</span>
              </div>
            </NavLink>
          </div>

          {/* toggle icon
          <div
            className="toggle-icon btn mt-5 mb-5"
            onClick={() => {
              setActiveToggle(!activeToggle);
              dispatch(Toggle(!activeToggle));
            }}
          >
            {activeToggle ? <AiFillCaretRight className="arrows" /> : <AiFillCaretLeft className="arrows" />}
          </div> */}

          <div>
            <NavLink
              to="/business"
              style={{ textDecoration: "none", color: "inherit" }}
              className={({ isActive }) => (isActive ? "activeLink" : null)}
            >
              <div className="sidebar-links">
                <DomainAddOutlinedIcon
                  className="sidePanelIcons"
                  sx={{ marginRight: "10px" }}
                />
                <span className="sidebar-links-name">Business Management</span>
              </div>
            </NavLink>
          </div>

          <div>
            <NavLink
              to="/subscriptions"
              style={{ textDecoration: "none", color: "inherit" }}
              className={({ isActive }) => (isActive ? "activeLink" : null)}
            >
              <div className="sidebar-links">
                <AccountBalanceWalletOutlinedIcon
                  className="sidePanelIcons"
                  sx={{ marginRight: "10px" }}
                />
                <span className="sidebar-links-name">Subscriptions</span>
              </div>
            </NavLink>
          </div>

          <div>
            <NavLink
              to="/transactions"
              style={{ textDecoration: "none", color: "inherit" }}
              className={({ isActive }) => (isActive ? "activeLink" : null)}
            >
              <div className="sidebar-links">
                <VerticalSplitIcon
                  className="sidePanelIcons"
                  sx={{ marginRight: "10px" }}
                />
                <span className="sidebar-links-name">Transactions</span>
              </div>
            </NavLink>
          </div>

          <div>
            {/* <NavLink
              to="/payment"
              style={{ textDecoration: "none", color: "inherit" }}
              className={({ isActive }) => (isActive ? "activeLink" : null)}
            >
              <div className="sidebar-links">
                <VerticalSplitIcon
                  className="sidePanelIcons"
                  sx={{ marginRight: "10px" }}
                />
                <span className="sidebar-links-name">Payment</span>
              </div>
            </NavLink> */}
          </div>

          <div className="sidebar-links mt-auto border-top text-center" onClick={logoutHandler}>
            <button className="logout-btn">
              <LogoutIcon
                className="sidePanelIcons"
                sx={{ marginRight: "10px" }}
              />
              <span className="sidebar-links-name">Logout</span>
            </button>
          </div>
        </div>

        {/* content */}
        <div className={activeToggle ? "activeContent" : "content"}>
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-default my-auto">
              <div>
                {/* <button
                  type="button"
                  className="sidePanelToggleBtn"
                  onClick={() => {
                    setActiveToggle(!activeToggle);
                  }}
                >
                  {activeToggle ? (
                    <MenuIcon sx={{ fontSize: "30px" }} />
                  ) : (
                    <MenuOpenIcon sx={{ fontSize: "30px" }} />
                  )}
                </button> */}
              </div>
              <div className="ms-auto">
                <ul className="navbar-nav justify-content-between">
                  {/* <li className="ms-auto">
                    <CgProfile
                      style={{
                        color: "#ffffff",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </li> */}
                  <li>
                    <div className="d-flex">
                      {/* <img
                        className="profilePicture"
                        src={userImg}
                        alt="profile-pic"
                      /> */}
                      {/* <div className="ms-sm-2 ms-1 my-auto userName">
                        Super-Admin user
                      </div> */}
                    </div>
                  </li>
                </ul>
              </div>
              {/* <button
                className="navbar-toggler ms-auto"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                onClick={() => {
                  setActiveNav(!activeNav)
                }}
              >
                <FaBars />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav justify-content-between  w-100"> 
                  <li className="mt-1 ms-auto">
                    <CgProfile
                      style={{
                        color: "#ffffff",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </li>
                </ul>
              </div> */}
            </nav>
          </div>
        </div>
      </div>
      <section
        // className={activeToggle ? "sidePanelCollapse" : "sidePanelExpand"}
        className="sidePanelCollapse"
      >
        <Outlet />
      </section>
    </div>

  );
};

export default Sidepanel;

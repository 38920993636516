export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const SUCCESS_CLEAR = "SUCCESS_CLEAR";
export const ERROR_CLEAR = "ERROR_CLEAR";
export const CLEAR = "CLEAR";

// LOGIN
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

// ADD PACKAGE
export const ADD_PACKAGE_START = "ADD_PACKAGE_START";
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS";
export const ADD_PACKAGE_FAIL = "ADD_PACKAGE_FAIL";

// GET PACKAGE
export const GET_PACKAGE_START = "GET_PACKAGE_START";
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS";
export const GET_PACKAGE_FAIL = "GET_PACKAGE_FAIL";

// DELETE PACKAGE
export const DELETE_PACKAGE_START = "DELETE_PACKAGE_START";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_FAIL = "DELETE_PACKAGE_FAIL";

// EDIT PACKAGE
export const EDIT_PACKAGE_START = "EDIT_PACKAGE_START";
export const EDIT_PACKAGE_SUCCESS = "EDIT_PACKAGE_SUCCESS";
export const EDIT_PACKAGE_FAIL = "EDIT_PACKAGE_FAIL";

//GET ALL BUSINESSES
export const GET_ALL_BUSINESSES_START = "GET_ALL_BUSINESSES_START";
export const GET_ALL_BUSINESSES_SUCCESS = "GET_ALL_BUSINESSES_SUCCESS";
export const GET_ALL_BUSINESSES_FAIL = "GET_ALL_BUSINESSES_FAIL";

//EDIT BUSINESSES
export const EDIT_BUSINESS_START = "EDIT_BUSINESS_START";
export const EDIT_BUSINESS_SUCCESS = "EDIT_BUSINESS_SUCCESS";
export const EDIT_BUSINESS_FAIL = "EDIT_BUSINESS_FAIL";

//EDIT BUSINESSES STATUS
export const EDIT_STATUS_START = "EDIT_STATUS_START";
export const EDIT_STATUS_SUCCESS = "EDIT_STATUS_SUCCESS";
export const EDIT_STATUS_FAIL = "EDIT_STATUS_FAIL";

//ADD BUSINESSES
export const ADD_BUSINESS_START = "ADD_BUSINESS_START";
export const ADD_BUSINESS_SUCCESS = "ADD_BUSINESS_SUCCESS";
export const ADD_BUSINESS_FAIL = "ADD_BUSINESS_FAIL";

// DELETE BUSINESS
export const DELETE_BUSINESS_START = "DELETE_BUSINESS_START";
export const DELETE_BUSINESS_SUCCESS = "DELETE_BUSINESS_SUCCESS";
export const DELETE_BUSINESS_FAIL = "DELETE_BUSINESS_FAIL";

//GET ALL TRANSACTIONS
export const GET_TRANSACTIONS_START = "GET_TRANSACTIONS_START";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";

//GET ALL TRANSACTIONS
export const GET_SUBSCRIPTIONS_START = "GET_SUBSCRIPTIONS_START";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL";

//SEND MAIL
export const SEND_PLAN_CHANGE_EMAIL_START = "SEND_PLAN_CHANGE_EMAIL_START";
export const SEND_PLAN_CHANGE_EMAIL_SUCCESS = "SEND_PLAN_CHANGE_EMAIL_SUCCESS";
export const SEND_PLAN_CHANGE_EMAIL_FAIL = "SEND_PLAN_CHANGE_EMAIL_FAIL"; 

//change plan
export const UPDATE_PLAN_CHANGE_START = "UPDATE_PLAN_CHANGE_START";
export const UPDATE_PLAN_CHANGE_SUCCESS = "UPDATE_PLAN_CHANGE_SUCCESS";
export const UPDATE_PLAN_CHANGE_FAIL = "UPDATE_PLAN_CHANGE_FAIL"; 

//ADD CHAT
export const ADD_CHAT_START = "ADD_CHAT_START";
export const ADD_CHAT_SUCCESS = "ADD_CHAT_SUCCESS";
export const ADD_CHAT_FAIL = "ADD_CHAT_FAIL";

//GET CHAT 
export const GET_CHAT_START = "GET_CHAT_START";
export const GET_CHAT_SUCCESS = "GET_CHAT_SUCCESS";
export const GET_CHAT_FAIL = "GET_CHAT_FAIL";

//EDIT CHAT
export const EDIT_CHAT_START = "EDIT_CHAT_START";
export const EDIT_CHAT_SUCCESS = "EDIT_CHAT_SUCCESS";
export const EDIT_CHAT_FAIL = "EDIT_CHAT_FAIL";

//DELETE CHAT
export const DELETE_CHAT_START = "DELETE_CHAT_START";
export const DELETE_CHAT_SUCCESS = "DELETE_CHAT_SUCCESS";
export const DELETE_CHAT_FAIL = "DELETE_CHAT_FAIL";

//Read Count CHAT
export const READ_COUNT_CHAT_START = "READ_COUNT_CHAT_START";
export const READ_COUNT_CHAT_SUCCESS = "READ_COUNT_CHAT_SUCCESS";
export const READ_COUNT_CHAT_FAIL = "READ_COUNT_CHAT_FAIL";

//update read count
export const UPDATE_COUNT_CHAT_START = "UPDATE_COUNT_CHAT_START";
export const UPDATE_COUNT_CHAT_SUCCESS = "UPDATE_COUNT_CHAT_SUCCESS";
export const UPDATE_COUNT_CHAT_FAIL = "UPDATE_COUNT_CHAT_FAIL";

//dashoboard count
export const DASHBOARD_COUNT_START = "DASHBOARD_COUNT_START";
export const DASHBOARD_COUNT_SUCCESS = "DASHBOARD_COUNT_SUCCESS";
export const DASHBOARD_COUNT_FAIL = "DASHBOARD_COUNT_FAIL";
import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import { alert } from "./alertReducer";
import packageManager from "./packageReducer";
import businessReducer from "./businessReducer";
import transactionsReducer from "./transactionsReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import chatReducer from "./chatReducer";

export default combineReducers({
  alert,
  loginReducer,
  packageManager,
  businessReducer,
  transactionsReducer,
  subscriptionsReducer,
  chatReducer
});

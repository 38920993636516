/*eslint-disable import/no-anonymous-default-export*/
import {
  ADD_PACKAGE_FAIL,
  ADD_PACKAGE_START,
  ADD_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  DELETE_PACKAGE_START,
  DELETE_PACKAGE_SUCCESS,
  EDIT_PACKAGE_FAIL,
  EDIT_PACKAGE_START,
  EDIT_PACKAGE_SUCCESS,
  GET_PACKAGE_FAIL,
  GET_PACKAGE_START,
  GET_PACKAGE_SUCCESS,
  SEND_PLAN_CHANGE_EMAIL_START,
  SEND_PLAN_CHANGE_EMAIL_SUCCESS,
  SEND_PLAN_CHANGE_EMAIL_FAIL,
} from "../Actions/Types";

const initialState = {
  getPackageResponse: [],
  response: "",
  error: "",
  getLoading: false,
  deleteLoading: false,
  editLoading: false,
  addLoading: false,
  isLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_PACKAGE_START:
      return {
        ...state,
        addLoading: true,
      };
    case ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        addLoading: false,
        response: payload,
      };
    case ADD_PACKAGE_FAIL:
      return {
        ...state,
        addLoading: false,
        error: payload.errors,
      };
    case GET_PACKAGE_START:
      return {
        ...state,
        getLoading: true,
      };
    case GET_PACKAGE_SUCCESS:
      return {
        ...state,
        getLoading: false,
        getPackageResponse: payload,
      };
    case GET_PACKAGE_FAIL:
      return {
        ...state,
        getLoading: false,
        error: payload,
      };
    case DELETE_PACKAGE_START:
      return {
        ...state,
        deleteLoading: true,
      };
    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        response: payload,
      };
    case DELETE_PACKAGE_FAIL:
      return {
        ...state,
        deleteLoading: false,
        error: "Could not delete package",
      };
    case EDIT_PACKAGE_START:
      return {
        ...state,
        editLoading: true,
      };
    case EDIT_PACKAGE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        response: payload,
      };
    case EDIT_PACKAGE_FAIL:
      return {
        ...state,
        editLoading: false,
        error: payload,
      };
    case SEND_PLAN_CHANGE_EMAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_PLAN_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        response: payload,
      };
    case SEND_PLAN_CHANGE_EMAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    default:
      return state;
  }
}

import React, { Fragment, useState, useEffect } from "react";
import styles from "./BusinessManagement.module.css";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PaymentIcon from "@mui/icons-material/Payment";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import BusinessModal from "../BusinessModal/BusinessModal";
import { useSelector, useDispatch } from "react-redux";
import {
  getBusinesses,
  editStatus,
  deleteBusiness,
} from "../../Redux/Actions/businessActions";
import AlertModal from "../AlertModal/AlertModal";
import usePagination from "../Common/Pagination";
import { Pagination } from "@mui/material";
import EditPayment from "../../Payment/EditPayment";
import ChatIcon from "@mui/icons-material/Chat";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { dateFormatUTCtoddmmyyyy } from "../Common/date";

function BusinessManagement() {
  let activeBusinesses = [];
  let inActiveBusinesses = [];
  let initialModalValues = {
    first_name: "",
    last_name: "",
    business_name: "",
    admin_email: "",
    package_id: "",
    address: "",
  };
  const per_page = 10;

  const [showAddBusinessModal, setShowAddBusinessModal] = useState({
    show: false,
    data: { ...initialModalValues },
  });
  const [showEditBusinessModal, setShowEditBusinessModal] = useState({
    show: false,
    data: { ...initialModalValues },
  });
  const [showEditPaymentModel, setShowEditPaymentModel] = useState({
    show: false,
    data: {},
  });

  //Redux Dispatch
  const dispatch = useDispatch();

  const businessesResponse = useSelector((state) => state.businessReducer);
  const alert = useSelector((state) => state.alert);

  if (
    businessesResponse.response.data &&
    Array.isArray(businessesResponse.response.data)
  ) {
    activeBusinesses = businessesResponse.response.data.filter(
      (item) => item.status === 1
    );
    inActiveBusinesses = businessesResponse.response.data.filter(
      (item) => item.status === 2
    );
  } else {
    activeBusinesses = [];
    inActiveBusinesses = [];
  }

  //pagination code starts//
  const activePaginatedData = usePagination(activeBusinesses, per_page);
  const inActivePaginatedData = usePagination(inActiveBusinesses, per_page);

  const activePaginationHandler = (event, value) => {
    activePaginatedData.jump(value);
  };

  const inActivePaginationHandler = (event, value) => {
    inActivePaginatedData.jump(value);
  };
  //pagination code ends//

  //function to open edit business modal and to append data
  const editRowHandler = (rowData) => {
    setShowEditBusinessModal({ show: true, data: { ...rowData } });
  };

  const editPaymentHandler = (rowData) => {
    // console.log("yes", showEditPaymentModel)
    setShowEditPaymentModel({ show: true, data: { ...rowData } });
  };

  const deleteRowHandler = (id) => {
    dispatch(deleteBusiness(id));
  };

  //functions to open/close add business modal
  function openAddBusinessModal() {
    setShowAddBusinessModal((pre) => {
      return { ...pre, show: true };
    });
  }

  function closeBusinessModal() {
    setShowAddBusinessModal((pre) => {
      return { ...pre, show: false };
    });
    setShowEditBusinessModal((pre) => {
      return { ...pre, show: false };
    });
    setShowEditPaymentModel((pre) => {
      return { ...pre, show: false };
    });
  }

  const onStatusChangeHanlder = (data) => {
    if (data.status === 1) {
      let apiBody = { status: 2 };
      dispatch(editStatus(data.id, apiBody));
    } else if (data.status === 2) {
      let apiBody = { status: 1 };
      dispatch(editStatus(data.id, apiBody));
    }
  };

  // UseEffects (start):
  useEffect(() => {
    dispatch(getBusinesses());
  }, [dispatch]);

  //api call after updating suggestion box status
  useEffect(() => {
    if (alert.type === "success") {
      dispatch(getBusinesses());
    }
  }, [alert, dispatch]);
  // UseEffects (end):

  return (
    <Fragment>
      <div className="outerPage">
        <div>
          <h4>Business Management</h4>
        </div>
        <div className="innerPage p-2">
          <div className={`m-2 mb-3 ps-2`}>
            <button
              type="button"
              className={`btn fillBtn`}
              onClick={openAddBusinessModal}
            >
              Add Business
            </button>
          </div>
          <BusinessModal
            addData={showAddBusinessModal}
            editData={showEditBusinessModal}
            closeModal={closeBusinessModal}
          />
          <EditPayment
            showPayment={showEditPaymentModel}
            closeModal={closeBusinessModal}
          />
          {alert.message && <AlertModal show={true} />}
          <div className="mt-2">
            <ul className={`nav nav-tabs justify-content-center`}>
              <li className="nav-item">
                <a
                  className={`nav-link active`}
                  data-bs-toggle="tab"
                  href="#active"
                >
                  Active
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link`} data-bs-toggle="tab" href="#inActive">
                  In-Active
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="active">
                <div className={`${styles.tableContainer}`}>
                  <table className={`my-4`}>
                    <thead>
                      <tr>
                        <th>SL No:</th>
                        <th>Admin Name</th>
                        <th>Business Name</th>
                        <th>Email</th>
                        {/* <th>Address</th> */}
                        <th>Package</th>
                        <th>Plan</th>
                        <th>Last Activity</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!businessesResponse.getBusinessesLoading &&
                        activePaginatedData.currentData().map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {row.first_name}&nbsp;{row.last_name}
                            </td>
                            <td>{row.business_name}</td>
                            <td>{row.email_id}</td>
                            {/* <td>{row.address}</td> */}
                            <td>{row.package_name}</td>
                            <td>
                              {row.package_type === 1 ? "Yearly" : "Monthly"}
                            </td>
                            <td>{row.last_activity !== null ? dateFormatUTCtoddmmyyyy(row.last_activity) : null}</td>
                            <td>
                              <div
                                className={`form-check form-switch d-flex justify-content-center`}
                              >
                                <input
                                  className={`form-check-input ${styles.switch}`}
                                  onChange={() => {
                                    onStatusChangeHanlder(row);
                                  }}
                                  type="checkbox"
                                  id="switch"
                                  checked={row.status === 1 ? true : false}
                                />
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.actionCell}`}>
                                <Dropdown drop="start">
                                  <Dropdown.Toggle id="dropdown-basic">
                                    {/* <UilEllipsisH color="#7c828d" size="18" /> */}
                                    <MoreHorizIcon style={{color:"#7c828d"}}/>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                       onClick={() => {
                                        editRowHandler(row);
                                      }}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <BorderColorIcon style={{ fontSize: 18 }} className="me-2"/>
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={() => {
                                        editPaymentHandler(row);
                                      }}
                                    >
                                      <PaymentIcon style={{ fontSize: 18 }} className="me-2"/>
                                      Modify Payment
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        color: "#e04f44",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={() => {
                                        deleteRowHandler(row.id);
                                      }}
                                    >
                                       <DeleteTwoToneIcon style={{ fontSize: 18 }} className="me-2"/>
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {businessesResponse.getBusinessesLoading && (
                    <div className="text-center my-5">
                      <span
                        className="spinner-border table-spinner"
                        role="status"
                      ></span>
                    </div>
                  )}
                  {!businessesResponse.getBusinessesLoading ? (
                    activeBusinesses.length > 0 ? null : (
                      <p className="text-center h5 mt-5">No Data Available</p>
                    )
                  ) : null}
                  {!businessesResponse.getBusinessesLoading &&
                    activeBusinesses.length > 0 && (
                      <div className="d-flex justify-content-center">
                        <Pagination
                          count={activePaginatedData.maxPage}
                          page={activePaginatedData.currentPage}
                          color="secondary"
                          shape="circular"
                          variant="outlined"
                          default={activePaginatedData.currentPage}
                          onChange={activePaginationHandler}
                        />
                      </div>
                    )}
                </div>
              </div>
              <div className="tab-pane" id="inActive">
                <div className={`${styles.tableContainer}`}>
                  <table className={`my-4`}>
                    <thead>
                      <tr>
                        <th>SL No:</th>
                        <th>Admin Name</th>
                        <th>Business Name</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Package</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!businessesResponse.getBusinessesLoading &&
                        inActivePaginatedData
                          .currentData()
                          .map((row, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {row.first_name}&nbsp;{row.last_name}
                              </td>
                              <td>{row.business_name}</td>
                              <td>{row.email_id}</td>
                              <td>{row.address}</td>
                              <td>{row.package_name}</td>
                              <td>
                                <div
                                  className={`form-check form-switch d-flex justify-content-center`}
                                >
                                  <input
                                    className={`form-check-input ${styles.switch}`}
                                    onChange={() => {
                                      onStatusChangeHanlder(row);
                                    }}
                                    type="checkbox"
                                    id="switch"
                                    checked={row.status === 2 ? false : true}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className={`${styles.actionCell}`}>
                                  <span className="me-1">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        editRowHandler(row);
                                      }}
                                      className={`btn ${styles.actionEditBtn}`}
                                    >
                                      <BorderColorIcon />
                                    </button>
                                  </span>
                                  <span className="me-1">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        deleteRowHandler(row.id);
                                      }}
                                      className={`btn ${styles.actionDelBtn}`}
                                    >
                                      <DeleteTwoToneIcon />
                                    </button>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  {businessesResponse.getBusinessesLoading && (
                    <div className="text-center my-5">
                      <span
                        className={`spinner-border ${styles.spinner}`}
                        role="status"
                      ></span>
                    </div>
                  )}
                  {!businessesResponse.getBusinessesLoading ? (
                    inActiveBusinesses.length > 0 ? null : (
                      <p className="text-center h5 mt-5">No Data Available</p>
                    )
                  ) : null}
                  {!businessesResponse.getBusinessesLoading &&
                    inActiveBusinesses.length > 0 && (
                      <div className="d-flex justify-content-center">
                        <Pagination
                          count={inActivePaginatedData.maxPage}
                          page={inActivePaginatedData.currentPage}
                          color="secondary"
                          shape="circular"
                          variant="outlined"
                          default={inActivePaginatedData.currentPage}
                          onChange={inActivePaginationHandler}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BusinessManagement;

import React from "react";
import { Fragment } from "react";

function ErrorComponent(props) {
  return (
    <Fragment>
      <div className="error">{props.children}</div>
    </Fragment>
  );
}

export default ErrorComponent;

import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { Formik, Form, Field } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "react-bootstrap";
import { businessModalErrors } from "../Common/Constants";
import { addBusiness, editBusiness } from "../../Redux/Actions/businessActions";
import { useSelector, useDispatch } from "react-redux";
import { dateFormatmmddyyyytoUTC } from "../Common/date";

import styles from "./BusinessModal.module.css";
import { getPackageManager } from "../../Redux/Actions/packageAction";

const BusinessModal = (props) => {
  let formValues;
  let packages = [];
  const { addData, editData, closeModal } = { ...props };

  const date = new Date();
  const currentDate = `${date.getFullYear()}-${
    date.getMonth() + 1 < 10
      ? 0 + "" + (date.getMonth() + 1)
      : date.getMonth() + 1
  }-${date.getDate() < 10 ? 0 + "" + date.getDate() : date.getDate()}`;

  //Redux Dispatch
  const dispatch = useDispatch();

  const packageResponse = useSelector((state) => state.packageManager);

  if (packageResponse.getPackageResponse) {
    packages = packageResponse.getPackageResponse;
  } else {
    packages = [];
  }

  if (addData.show === true) {
    formValues = {
      first_name: "",
      last_name: "",
      business_name: "",
      admin_email: "",
      package_id: "",
      transaction_id: "",
      payment_date: "",
      address: "",
      plan: "",
    };
  } else if (editData.show === true) {
    formValues = {
      first_name: editData.data.first_name,
      last_name: editData.data.last_name,
      business_name: editData.data.business_name,
      admin_email: editData.data.email_id,
      package_id: editData.data.package_id,
      payment_date: "",
      transaction_id: "",
      address: editData.data.address,
      plan: editData.data.package_type,
    };
  }

  // Validations (start):
  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = businessModalErrors.FIRST_NAME;
    }
    if (!values.last_name) {
      errors.last_name = businessModalErrors.LAST_NAME;
    }
    if (!values.business_name) {
      errors.business_name = businessModalErrors.BUSINESS_NAME;
    }
    if (!values.admin_email) {
      errors.admin_email = businessModalErrors.EMAIL;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.admin_email)
    ) {
      //eslint-disable-line
      errors.admin_email = businessModalErrors.INVALID_EMAIL;
    }
    if (!values.package_id) {
      errors.package_id = businessModalErrors.PACKAGE;
    }
    if (!values.transaction_id) {
      errors.transaction_id = businessModalErrors.TRANSACTION_ID;
    }
    if (!values.payment_date) {
      errors.payment_date = businessModalErrors.PAYMENT_DATE;
    }
    if (!values.plan) {
      errors.plan = businessModalErrors.PLAN;
    }
    return errors;
  };
  // Validations (end):

  const handleSubmit = (modalData) => {
    if (addData.show) {
      let apiAddData = {
        ...modalData,
        payment_date: dateFormatmmddyyyytoUTC(modalData.payment_date),
      };
      dispatch(addBusiness(apiAddData));
    } else if (editData.show) {
     // console.log(modalData)
      let apiEditData = {
        business_name: modalData.business_name,
        address: modalData.address,
        package_id: modalData.package_id,
        transaction_id: modalData.transaction_id,
        plan: modalData.plan,
        payment_date: dateFormatmmddyyyytoUTC(modalData.payment_date),
      };
      dispatch(editBusiness(editData.data.id, apiEditData));
    }
    closeModal();
  };

  // UseEffects (start):
  useEffect(() => {
    dispatch(getPackageManager());
  }, [dispatch]);
  // UseEffects (end):

  const planOptions = [
    { id: 1, label: "Yearly", value: 1 },
    { id: 2, label: "Monthly", value: 2 },
  ];

  return (
    <Modal
      show={addData.show || editData.show}
      onHide={closeModal}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header
        style={{
          background: "#eff0f3",
          color: "var(--color-grey-0)",
          fontWeight: "500",
          fontSize: "20px",
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-10px",
          height:"49px",
          borderBottom: "1px solid #e9ebf0"
        }}
        className="mb-2"
      >
        {addData.show && "Add New Business"}
        {editData.show && "Edit Business"}
        <CloseIcon
          onClick={closeModal}
          sx={{ cursor: "pointer", display: { xs: "none", md: "block" } }}
        />
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={formValues}
          validate={(values) => validate(values)}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className={`mb-4 ${styles.container}`}>
                <div className="row mb-3">
                  <div className="col-6">
                    <label className="modal-labels" htmlFor="first_name">
                      First Name:<span className="text-danger">*</span>
                    </label>
                    <Field
                      id="first_name"
                      name="first_name"
                      type="text"
                      className={
                        touched.first_name && errors.first_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="First Name"
                      disabled={editData.show}
                    />
                    {errors.first_name && touched.first_name && (
                      <div className="invalid-feedback">
                        {errors.first_name}
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <label className="modal-labels" htmlFor="last_name">
                      Last Name:<span className="text-danger">*</span>
                    </label>
                    <Field
                      id="last_name"
                      name="last_name"
                      type="text"
                      className={
                        touched.last_name && errors.last_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Last Name"
                      disabled={editData.show}
                    />
                    {errors.last_name && touched.last_name && (
                      <div className="invalid-feedback">{errors.last_name}</div>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="modal-labels" htmlFor="business_name">
                    Business Name:<span className="text-danger">*</span>
                  </label>
                  <Field
                    id="business_name"
                    name="business_name"
                    type="text"
                    className={
                      touched.business_name && errors.business_name
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Business Name"
                  />
                  {errors.business_name && touched.business_name && (
                    <div className="invalid-feedback">
                      {errors.business_name}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label className="modal-labels" htmlFor="admin_email">
                    Email address:<span className="text-danger">*</span>
                  </label>
                  <Field
                    id="admin_email"
                    name="admin_email"
                    type="text"
                    className={
                      touched.admin_email && errors.admin_email
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Email address"
                    disabled={editData.show}
                  />
                  {errors.admin_email && touched.admin_email && (
                    <div className="invalid-feedback">{errors.admin_email}</div>
                  )}
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="modal-labels" htmlFor="package_id">
                      Package:<span className="text-danger">*</span>
                    </label>
                    <Field
                      as="select"
                      id="package_id"
                      name="package_id"
                      className={
                        touched.package_id && errors.package_id
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                    >
                      <option defaultValue="" value="" className="my-2">
                        Select the Package
                      </option>
                      {packages.map((item, index) => (
                        <option
                          key={item.package.id}
                          value={item.package.id}
                          className="my-2"
                        >
                          {item.package.package_name}
                        </option>
                      ))}
                    </Field>
                    {errors.package_id && touched.package_id && (
                      <div className="invalid-feedback">
                        {errors.package_id}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="modal-labels" htmlFor="package_id">
                      Plan:<span className="text-danger">*</span>
                    </label>
                    <Field
                      as="select"
                      id="plan"
                      name="plan"
                      className={
                        touched.plan && errors.plan
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                    >
                      <option defaultValue="" value="" className="my-2">
                        Select the plan
                      </option>
                      {planOptions.map((item, index) => (
                        <option
                          key={item.id}
                          value={item.value}
                          className="my-2"
                        >
                          {item.label}
                        </option>
                      ))}
                    </Field>
                    {errors.plan && touched.plan && (
                      <div className="invalid-feedback">{errors.plan}</div>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="modal-labels" htmlFor="transaction_id">
                    Transaction ID:<span className="text-danger">*</span>
                  </label>
                  <Field
                    id="transaction_id"
                    name="transaction_id"
                    type="text"
                    className={
                      touched.transaction_id && errors.transaction_id
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Transaction ID"
                  />
                  {errors.transaction_id && touched.transaction_id && (
                    <div className="invalid-feedback">
                      {errors.transaction_id}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label className="modal-labels" htmlFor="payment_date">
                    Payment Date:<span className="text-danger">*</span>
                  </label>
                  {/* <DatePicker dateFormat="dd/MM/yyyy" selected={date} onChange={(date) => setDate(date)} /> */}
                  <Field
                    id="payment_date"
                    name="payment_date"
                    type="date"
                    placeholder="dd-mm-yyyy"
                    max={currentDate}
                    className={
                      touched.payment_date && errors.payment_date
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {errors.payment_date && touched.payment_date && (
                    <div className="invalid-feedback">
                      {errors.payment_date}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label className="modal-labels" htmlFor="address">
                    Address:
                  </label>
                  <Field
                    id="address"
                    name="address"
                    type="text"
                    className="form-control"
                    placeholder="Address"
                  />
                </div>
              </div>
              <div
                className={`d-flex justify-content-end mt-4 mb-3 ${styles.container}`}
              >
                <Button
                  className="btn outLineBtn"
                  type="button"
                  variant="outlined"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                {addData.show && (
                  <button
                    className={`btn fillBtn ms-3`}
                    variant="filled"
                    type="submit"
                  >
                    Send Invite
                  </button>
                )}
                {editData.show && (
                  <button
                    className={`btn fillBtn ms-3`}
                    variant="filled"
                    type="submit"
                  >
                    Re-Send Invite
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default BusinessModal;

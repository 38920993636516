import React from "react";
import "./ChangePlan.css";
import { useNavigate } from "react-router";

const ChangePlan = () => {
  const navigate = useNavigate();
  const changePlan = (value) => {
    navigate("/plan-form/"+value);
  };
  return (
    <>
      <div className="outerPage">
        <div>
          <h4>Change Plan</h4>
        </div>
        <div className="innerPage p-2">
          <div className="row change-plan" style={{ marginTop: "120px" }}>
            <div className="col-md-4">
              <div>
                <h5
                  style={{ fontWeight: "bolder", fontSize: "32px" }}
                  className="text-center"
                >
                  Downgrade Your Tasforc Subscription
                </h5>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="mt-5"
                >
                  <button onClick={() => changePlan(1)}>
                    Downgrade Subscription
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <h5
                  style={{ fontWeight: "bolder", fontSize: "32px" }}
                  className="text-center"
                >
                  Upgrade Your Tasforc Subscription
                </h5>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="mt-5"
                >
                  <button onClick={()=>changePlan(2)}>Upgrade Subscription</button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <h5
                  style={{ fontWeight: "bolder", fontSize: "32px" }}
                  className="text-center"
                >
                  Cancel Your Tasforc Subscription
                </h5>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="mt-5"
                >
                  <button onClick={()=>changePlan(3)}>Cancel Subscription</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePlan;

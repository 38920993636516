export const roleId = {
    SUPER_ADMIN_USER: 11,
    ADMIN_USER: 1,
    TEAM_MEMBER: 2,
    USER: 3
  }

  export const loginError = {
    INVALID_ROLE: "Invalid user role",
  }

export const emailErrors = {
    EMAIL : "Please enter your Email address",
    INVALID_EMAIL : "Email is invalid",
 }

 export const passwordErrors = {
    PASSWORD : "Please enter your Password",
    CONFIRM_PASSWORD : 'Please Confirm your Password',
    PASSWORD_INVALID : "Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    PASSWORDS_UNMATCHED : "Password and Confirm Password doesn't match",
    OLD_NEW_SAME : "Old Password and New Password must not be same",
 }

 export const businessModalErrors = {
  FIRST_NAME : "First Name is required",
  LAST_NAME : "Last Name is required",
  BUSINESS_NAME : "Business Name is required",
  EMAIL : "Email is required",
  INVALID_EMAIL : "Email is invalid",
  PACKAGE:"Package is required",
  TRANSACTION_ID: "Transaction ID is required",
  PAYMENT_DATE: "Payment Date is required",
  PLAN:"Plan is required"
}
import React from "react";

const Payment = () => {
  return (
    <>
      <div className="outerPage">
        <div>
          <h4>Payment</h4>
        </div>
        <div className="innerPage p-2">
          <stripe-pricing-table
            pricing-table-id="prctbl_1MeAKXSAMlSeS7bbaOD3jD3c"
            publishable-key="pk_test_51MJXV2SAMlSeS7bbj7YGNq37RtqCjr6XEBElaZuO6kjRG6mWVP0tiFDtHft5bCJ5rQSdn6KktLb5b7b1i2mzfWkE00tgrjZYiA"
          ></stripe-pricing-table>
        </div>
      </div>
    </>
  );
};

export default Payment;

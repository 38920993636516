import React, { Fragment, useEffect } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import styles from "./PackageModal.module.css";
import {
  addPackageManager,
  getPackageManager,
  editPackageManager,
} from "../../Redux/Actions/packageAction";
import AlertModal from "../AlertModal/AlertModal";
import ErrorComponent from "../Common/ErrorComponent";
import { CircularProgress } from "@mui/material";

function PackageModal(props) {
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    package_name: yup.string().required("Package Name is required"),
    // package_price: yup.number().required("Package Price is required"),
    // validity: yup.number().required("Validity is required"),
    package_property: yup.array().of(
      yup.object().shape({
        property_name: yup.string().required("Property is required"),
        property_value: yup.number().required("Value is required"),
      })
    ),
    description: yup.string().required("Description is required"),
    package_type: yup.array().of(
      yup.object().shape({
        package_type_id: yup.string().required("Package type id is required"),
        package_type_name: yup.string().required("Package type is required"),
        validity: yup.number().required("Validity is required"),
        price: yup.number().required("Package Price is required"),
      })
    ),
  });

  const alert = useSelector((state) => state.alert);
  const addPackageReducer = useSelector((state) => state.packageManager);

  let initialValues;
  const { openPackage, handleClose, editPackage } = props;

  if (openPackage.show === true) {
    initialValues = {
      package_name: "",
      package_price: "",
      validity: 0,
      package_property: [{ property_name: "", property_value: "" }],
      description: "",
      package_type: [
        {
          package_type_name: "Yearly",
          validity: "",
          price: "",
          package_type_value: 1,
          package_type_id: "",
        },
        {
          package_type_name: "Monthly",
          validity: "",
          price: "",
          package_type_value: 2,
          package_type_id: "",
        },
      ],
    };
  } else if (editPackage.show === true) {
    initialValues = {
      package_name: editPackage.data.package.package_name,
      package_property: editPackage.data.properties.map((ele) => ({
        property_name: ele.property_name,
        property_value: ele.property_value,
      })),
      description: editPackage.data.package.description,
      package_type: editPackage.data.packageType.map((ele) => ({
        package_type_name: ele.package_type === 1 ? "Yearly" : "Monthly",
        validity: ele.validity,
        price: ele.price,
        package_type_id: ele.id,
      })),
    };
  }

  const submitSuccess = (values) => {
    openPackage.show && dispatch(addPackageManager(values));
    editPackage.show &&
      dispatch(editPackageManager(editPackage.data.package.id, values));
    //console.log(values);
  };

  useEffect(() => {
    if (alert.type === "success") {
      dispatch(getPackageManager());
      handleClose();
    }
    // eslint-disable-next-line
  }, [alert]);

  return (
    <Fragment>
      {alert.message && <AlertModal show={true} />}
      <Modal
        show={openPackage.show || editPackage.show}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header
          style={{
            background: "#eff0f3",
            color: "var(--color-grey-0)",
            fontWeight: "500",
            fontSize: "20px",
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-10px",
            height:"49px",
            borderBottom: "1px solid #e9ebf0"
          }}
          className="mb-4"
        >
          {openPackage.show ? (
            <div>Add New Package</div>
          ) : (
            editPackage.show && <div>Edit Package</div>
          )}
          <CloseIcon
            onClick={handleClose}
            sx={{ cursor: "pointer", display: { xs: "none", md: "block" } }}
          />
        </Modal.Header>
        <Modal.Body className="p-4">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => submitSuccess(values)}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="d-md-flex justify-content-between align-items-center mb-3">
                  <div className="col-md mb-3">
                    <label className="modal-labels" htmlFor="name">
                      Package Name
                    </label>
                    <Field
                      id="name"
                      name="package_name"
                      type="text"
                      className={
                        touched.package_name && errors.package_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Package Name"
                    />
                    {errors.package_name && touched.package_name && (
                      <div className="invalid-feedback">
                        {errors.package_name}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="d-md-flex justify-content-between align-items-center mb-3">
                  <div className="col-md-5 mb-3">
                    <label className="modal-labels" htmlFor="package_type">
                      Package Type
                    </label>
                    <Field
                      as="select"
                      id="package_type"
                      name="package_type"
                      type="text"
                      className={
                        touched.package_type && errors.package_type
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="package_type in days"
                    >
                      <option defaultValue="" value="" className="my-2">
                        Select the Package type
                      </option>
                      {pakageTypeOptions.map((item, index) => (
                        <option
                          key={item.id}
                          value={item.value}
                          className="my-2"
                        >
                          {item.label}
                        </option>
                      ))}
                    </Field>
                    {errors.package_type && touched.package_type && (
                      <div className="invalid-feedback">
                        {errors.package_type}
                      </div>
                    )}
                  </div>
                </div> */}
                {/* <div className="d-md-flex justify-content-between align-items-center mb-3">
                  <div className="col-md-5 mb-3">
                    <label className="modal-labels" htmlFor="validity">
                      Validity (in Days)
                    </label>
                    <Field
                      id="validity"
                      name="validity"
                      min="0"
                      type="number"
                      className={
                        touched.validity && errors.validity
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                       placeholder="Validity in days"
                    />
                    {errors.validity && touched.validity && (
                      <div className="invalid-feedback">{errors.validity}</div>
                    )}
                  </div>
                  <div className="col-md-5 mb-3">
                    <label className="modal-labels" htmlFor="price">
                      Package Price
                    </label>
                    <Field
                      id="price"
                      name="package_price"
                      type="number"
                      className={
                        touched.package_price && errors.package_price
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Package Price"
                    />
                    {errors.package_price && touched.package_price && (
                      <div className="invalid-feedback">
                        {errors.package_price}
                      </div>
                    )}
                  </div>
                </div> */}
                <label className="modal-labels" htmlFor="charge">
                  Package type
                </label>
                <div className="d-lg-flex align-items-center mb-3">
                  {/* <div className="col me-lg-5"> */}

                  <FieldArray name="package_type">
                    {(fieldArrayProps) => {
                      const { form } = fieldArrayProps;
                      const { values } = form;
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          {values.package_type.map((each, idx) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "15px",
                                }}
                                key={`package_type-${idx}`}
                              >
                                <div className={styles.formArray}>
                                  <div className="col me-5 mb-2">
                                    <Field
                                      id="package_type_id"
                                      name={`package_type[${idx}].package_type_id`}
                                      type="text"
                                      placeholder="Package type id"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`package_type[${idx}].package_type_id`}
                                      component={ErrorComponent}
                                    />
                                  </div>
                                  <div className="col me-5 mb-2">
                                    <Field
                                      id="package_name"
                                      name={`package_type[${idx}].package_type_name`}
                                      type="text"
                                      placeholder="Package"
                                      className="form-control"
                                      disabled={true}
                                    />
                                    <ErrorMessage
                                      name={`package_type[${idx}].package_type_name`}
                                      component={ErrorComponent}
                                    />
                                  </div>
                                  <div className="col me-5 mb-2">
                                    <Field
                                      id="validity"
                                      name={`package_type[${idx}].validity`}
                                      type="number"
                                      placeholder="validity in days"
                                      className="form-control"
                                      min="0"
                                    />
                                    <ErrorMessage
                                      name={`package_type[${idx}].validity`}
                                      component={ErrorComponent}
                                    />
                                  </div>
                                  <div className="col me-5 mb-2">
                                    <Field
                                      id="price"
                                      name={`package_type[${idx}].price`}
                                      type="number"
                                      step="any"
                                      placeholder="Price"
                                      className="form-control"
                                      min="0"
                                    />
                                    <ErrorMessage
                                      name={`package_type[${idx}].price`}
                                      component={ErrorComponent}
                                    />
                                  </div>
                                </div>
                                {/* {idx === 0 ? (
                                    <button
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "600",
                                        border: "none",
                                        marginLeft: "20px",
                                      }}
                                      onClick={() =>
                                        push({
                                          property_name: "",
                                          property_value: "",
                                        })
                                      }
                                      type="button"
                                    >
                                      <AddCircleOutlineIcon
                                        sx={{ color: "primary.main" }}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "600",
                                        border: "none",
                                        marginLeft: "20px",
                                      }}
                                      onClick={() => remove(idx)}
                                      type="button"
                                    >
                                      <CloseIcon sx={{ color: "red" }} />
                                    </button>
                                  )} */}
                              </div>
                            );
                          })}
                        </div>
                      );
                    }}
                  </FieldArray>
                  {/* </div> */}
                </div>

                <div className="d-lg-flex align-items-center mb-3">
                  <div className="col me-lg-5">
                    <label className="modal-labels" htmlFor="charge">
                      Property
                    </label>
                    <div style={{fontSize:"12px", color:"GrayText"}}>Please add "Channel" "Users" and "Projects"</div>
                    <FieldArray name="package_property">
                      {(fieldArrayProps) => {
                        const { push, remove, form } = fieldArrayProps;
                        const { values } = form;
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            {values.package_property.map((each, idx) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "15px",
                                  }}
                                  key={`package_property-${idx}`}
                                >
                                  <div className={styles.formArray}>
                                    <div className="col me-5 mb-2">
                                      <Field
                                        id="property_name"
                                        name={`package_property[${idx}].property_name`}
                                        type="text"
                                        placeholder="Property"
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name={`package_property[${idx}].property_name`}
                                        component={ErrorComponent}
                                      />
                                    </div>
                                    <div className="col me-5 mb-2">
                                      <Field
                                        id="property_value"
                                        name={`package_property[${idx}].property_value`}
                                        type="number"
                                        step="any"
                                        placeholder="Value"
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name={`package_property[${idx}].property_value`}
                                        component={ErrorComponent}
                                      />
                                    </div>
                                  </div>
                                  {idx === 0 ? (
                                    <button
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "600",
                                        border: "none",
                                        marginLeft: "20px",
                                      }}
                                      onClick={() =>
                                        push({
                                          property_name: "",
                                          property_value: "",
                                        })
                                      }
                                      type="button"
                                    >
                                      <AddCircleOutlineIcon
                                        sx={{ color: "primary.main" }}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "600",
                                        border: "none",
                                        marginLeft: "20px",
                                      }}
                                      onClick={() => remove(idx)}
                                      type="button"
                                    >
                                      <CloseIcon sx={{ color: "red" }} />
                                    </button>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        );
                      }}
                    </FieldArray>
                  </div>
                </div>
                <div className="d-lg-flex align-items-center nb-3">
                  <div className="col">
                    <label className="modal-labels" htmlFor="description">
                      Description
                    </label>
                    <Field
                      as="textarea"
                      id="description"
                      type="text"
                      name="description"
                      className={
                        touched.description && errors.description
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Description"
                      rows="3"
                    />
                    {errors.description && touched.description && (
                      <div className="invalid-feedback">
                        {errors.description}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center mb-3 mt-3">
                  <button
                    className="btn outLineBtn"
                    onClick={props.handleClose}
                    type="button"
                  >
                    Cancel
                  </button>
                  {openPackage.show && (
                    <button
                      variant="filled"
                      type="submit"
                      className={`w-10 ms-3 btn fillBtn`}
                    >
                      {addPackageReducer.addLoading === true && (
                        <span>
                          <CircularProgress
                            className="align-middle"
                            sx={{
                              color: "white",
                              height: "20px !important",
                              width: "20px !important",
                              marginRight: "10px",
                              textAlign: "center",
                            }}
                          />
                        </span>
                      )}
                      Add Package
                    </button>
                  )}
                  {editPackage.show && (
                    <button
                      variant="filled"
                      type="submit"
                      className={`w-10 ms-3 btn fillBtn`}
                    >
                      {addPackageReducer.editLoading === true && (
                        <CircularProgress
                          className="align-middle"
                          sx={{
                            color: "white",
                            height: "20px !important",
                            width: "20px !important",
                            marginRight: "10px",
                          }}
                        />
                      )}
                      Update Package
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default PackageModal;

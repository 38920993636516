/* eslint-disable import/no-anonymous-default-export */
import {
  GET_ALL_BUSINESSES_START,
  GET_ALL_BUSINESSES_SUCCESS,
  GET_ALL_BUSINESSES_FAIL,
  EDIT_STATUS_START,
  EDIT_STATUS_SUCCESS,
  EDIT_STATUS_FAIL,
  ADD_BUSINESS_START,
  ADD_BUSINESS_SUCCESS,
  ADD_BUSINESS_FAIL,
  EDIT_BUSINESS_START,
  EDIT_BUSINESS_SUCCESS,
  EDIT_BUSINESS_FAIL,
  DELETE_BUSINESS_START,
  DELETE_BUSINESS_SUCCESS,
  DELETE_BUSINESS_FAIL,
  UPDATE_PLAN_CHANGE_START,
  UPDATE_PLAN_CHANGE_SUCCESS,
  UPDATE_PLAN_CHANGE_FAIL,
  DASHBOARD_COUNT_START,
  DASHBOARD_COUNT_SUCCESS,
  DASHBOARD_COUNT_FAIL
} from "../Actions/Types";

const initialState = {
  response: "",
  error: "",
  getBusinessesLoading: false,
  editStatusLoading: false,
  addBusinessLoading: false,
  editBusinessLoading: false,
  updateBusinessPackage: false,
  getDashboardLoading:false,
  getDashboardCount:""
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_BUSINESSES_START:
      return {
        ...state,
        getBusinessesLoading: true,
      };
    case GET_ALL_BUSINESSES_SUCCESS:
      return {
        ...state,
        response: payload,
        getBusinessesLoading: false,
      };
    case GET_ALL_BUSINESSES_FAIL:
      return {
        ...state,
        error: payload,
        getBusinessesLoading: false,
      };
    case EDIT_STATUS_START:
      return {
        ...state,
        editStatusLoading: true,
      };
    case EDIT_STATUS_SUCCESS:
      return {
        ...state,
        response: payload,
        editStatusLoading: false,
      };
    case EDIT_STATUS_FAIL:
      return {
        ...state,
        error: payload,
        editStatusLoading: false,
      };
    case ADD_BUSINESS_START:
      return {
        ...state,
        addBusinessLoading: true,
      };
    case ADD_BUSINESS_SUCCESS:
      return {
        ...state,
        response: payload,
        addBusinessLoading: false,
      };
    case ADD_BUSINESS_FAIL:
      return {
        ...state,
        error: payload,
        addBusinessLoading: false,
      };
    case EDIT_BUSINESS_START:
      return {
        ...state,
        editBusinessLoading: true,
      };
    case EDIT_BUSINESS_SUCCESS:
      return {
        ...state,
        response: payload,
        editBusinessLoading: false,
      };
    case EDIT_BUSINESS_FAIL:
      return {
        ...state,
        error: payload,
        editBusinessLoading: false,
      };
    case DELETE_BUSINESS_START:
      return {
        ...state,
      };
    case DELETE_BUSINESS_SUCCESS:
      return {
        ...state,
        response: payload,
      };
    case DELETE_BUSINESS_FAIL:
      return {
        ...state,
        error: payload,
      };
    case UPDATE_PLAN_CHANGE_START:
      return {
        ...state,
        updateBusinessPackage: true,
      };
    case UPDATE_PLAN_CHANGE_SUCCESS:
      return {
        ...state,
        response: payload,
        updateBusinessPackage: false,
      };
    case UPDATE_PLAN_CHANGE_FAIL:
      return {
        ...state,
        error: payload,
        updateBusinessPackage: false,
      };
      case DASHBOARD_COUNT_START:
      return {
        ...state,
        getDashboardLoading: true,
      };
    case DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        getDashboardCount: payload,
        getBusinessesLoading: false,
      };
    case DASHBOARD_COUNT_FAIL:
      return {
        ...state,
        error: payload,
        getBusinessesLoading: false,
      };
    default:
      return state;
  }
}

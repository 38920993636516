//date format function, convert data from yyyy-mm-dd to dd-mon-yyyy
export const dateFormat = (date) => {

    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if(!date) {
      return "NA";
    } 
    //checking date is in yyyy-mm-dd format
    else if(/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(date)){
      let fDate = new Date(date);
      return (((fDate.getDate()<10)?(0+""+fDate.getDate()):(fDate.getDate()))+'-'+monthNames[fDate.getMonth()]+'-'+fDate.getFullYear());
    } else {
        return "Invalid Date"
    }
  }

//date format function, convert data from yyyy-mm-dd to UTC date
  export const dateFormatmmddyyyytoUTC = (date) => {
      let fDate = new Date(date);
      return fDate.toISOString();
  }

  //date format function, convert data from yyyy-mm-dd to dd/mm/yyyy
  export const dateFormatUTCtoddmmyyyy = (date) => {
    let fDate = new Date(date);
    // return (((fDate.getDate()<10)?(0+""+fDate.getDate()):(fDate.getDate()))+'/'+((fDate.getMonth()<10)?(0+""+(fDate.getMonth()+1)):(fDate.getMonth()+1))+'/'+fDate.getFullYear());
    return fDate.toLocaleDateString();
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./ChangePlan.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getPackageManager,
  send_email_plan_change,
} from "../../Redux/Actions/packageAction";

function PlanForm() {
  const location = useParams();
  const [plan, setPlan] = useState("");
  const packageResponse = useSelector((state) => state.packageManager);
  const dispatch = useDispatch();
  const [packageData, setPackageData] = useState([]);

  useEffect(() => {
    if (location) {
      if (Number(location.id) === 1) {
        setPlan("Let's Get You Downgraded!");
      }
      if (Number(location.id) === 2) {
        setPlan("Let's Get You Upgraded!");
      }
      if (Number(location.id) === 3) {
        setPlan("Sorry To Hear You're Wanting To Cancel!");
      }
    }
  }, [location]);

  useEffect(() => {
    dispatch(getPackageManager());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (packageResponse.getPackageResponse) {
      var finalData = [];
      for (var i = 0; i < packageResponse.getPackageResponse.length; i++) {
        if (packageResponse.getPackageResponse[i]) {
          finalData.push(packageResponse.getPackageResponse[i].package);
        }
      }
      setPackageData(finalData);
    }
  }, [packageResponse]);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    plan: "",
    change_plan: "",
    reason: "",
  };

  const UpgradeValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required!")
      .email("Email is invalid"),
    plan: Yup.string().required("Plan is required"),
    reason: Yup.string().required("Reason is required"),
  });
  const submit = (values) => {
    console.log(values);
    if (Number(location.id) === 1 && values.change_plan === "") {
      return;
    } else {
      const obj = {
        id: Number(location.id),
        data: values,
      };
      dispatch(send_email_plan_change(obj));
    }
  };

  return (
    <>
      <div className="outerPage">
        <div className="innerPage p-2">
          <h1 style={{ fontWeight: "bold", textAlign: "center" }}>{plan}</h1>
          <h2 style={{ textAlign: "center" }}>
            Just need some info before moving forward
          </h2>
          <div className="plan-form">
            <div className="d-flex justify-content-center">
              <Formik
                initialValues={initialValues}
                validationSchema={UpgradeValidationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={submit}
              >
                {({ errors, touched }) => (
                  <Form style={{ width: "500px" }}>
                    <div className=" mt-3">
                      <label>
                        First Name <span className="asterisk">*</span>
                      </label>
                      <Field
                        type="text"
                        id="first_name"
                        name="first_name"
                        className={
                          errors.first_name && touched.first_name
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Enter your First Name"
                      />
                      {/* error */}
                      {touched.first_name && errors.first_name ? (
                        <div className="invalid-feedback d-flex">
                          {/* <UilExclamationTriangle size="15" className="me-1" /> */}
                          {errors.first_name}
                        </div>
                      ) : null}
                    </div>

                    <div className=" mt-3">
                      <label>
                        Last Name <span className="asterisk">*</span>
                      </label>
                      <Field
                        type="text"
                        id="last_name"
                        name="last_name"
                        className={
                          errors.last_name && touched.last_name
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Enter your Last Name"
                      />
                      {/* error */}
                      {touched.last_name && errors.last_name ? (
                        <div className="invalid-feedback d-flex">
                          {/* <UilExclamationTriangle size="15" className="me-1" /> */}
                          {errors.last_name}
                        </div>
                      ) : null}
                    </div>

                    <div className=" mt-3">
                      <label>
                        Email <span className="asterisk">*</span>
                      </label>
                      <Field
                        type="text"
                        id="email"
                        name="email"
                        className={
                          errors.email && touched.email
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Enter your Email"
                      />
                      {/* error */}
                      {touched.email && errors.email ? (
                        <div className="invalid-feedback d-flex">
                          {/* <UilExclamationTriangle size="15" className="me-1" /> */}
                          {errors.email}
                        </div>
                      ) : null}
                    </div>

                    <div className=" mt-3">
                      <label>
                        Select your current Plan{" "}
                        <span className="asterisk">*</span>
                      </label>
                      <Field
                        as="select"
                        id="plan"
                        name="plan"
                        className={
                          touched.plan && errors.plan
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      >
                        <option defaultValue="" value="" className="my-2">
                          Select the plan
                        </option>
                        {packageData.map((item, index) => (
                          <option
                            key={item.id}
                            value={item.id}
                            className="my-2"
                          >
                            {item.package_name}
                          </option>
                        ))}
                      </Field>
                      {/* error */}
                      {touched.plan && errors.plan ? (
                        <div className="invalid-feedback d-flex">
                          {/* <UilExclamationTriangle size="15" className="me-1" /> */}
                          {errors.plan}
                        </div>
                      ) : null}
                    </div>

                    {Number(location.id) === 1 && (
                      <div className=" mt-3">
                        <label>
                          Which Plan Are You Looking To Downgrade To? *{" "}
                        </label>
                        <Field
                          as="select"
                          id="change_plan"
                          name="change_plan"
                          className={
                            touched.change_plan && errors.change_plan
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        >
                          <option defaultValue="" value="" className="my-2">
                            Select the Change plan
                          </option>
                          {packageData.map((item, index) => (
                            <option
                              key={item.id}
                              value={item.id}
                              className="my-2"
                            >
                              {item.package_name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    )}

                    <div className=" mt-3">
                      <label>Why are you wanting to change plan? *</label>
                      <Field
                        type="text"
                        id="reason"
                        name="reason"
                        className={
                          errors.reason && touched.reason
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Enter your Reason"
                      />
                      {/* error */}
                      {touched.reason && errors.reason ? (
                        <div className="invalid-feedback d-flex">
                          {/* <UilExclamationTriangle size="15" className="me-1" /> */}
                          {errors.reason}
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-5">
                      <button className="delete-btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanForm;

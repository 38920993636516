import React, { useEffect, useState } from "react";
import "./Chat.css";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addChats,
  deleteChats,
  editChats,
  getChats,
  updateReadCount,
} from "../../Redux/Actions/chatActions";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const Chat = () => {
  var { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  //console.log(location, " useLocation Hook");
  const { chats, isAddSuccess, isEditSuccess, isDeleteSuccess } = useSelector(
    (state) => state.chatReducer
  );
  const [chat, setChat] = useState("");
  const [initials, setInitial] = useState("");
  const [user, setUser] = useState(0);
  const [hidden, setHidden] = useState({});
  const [editChat, setEditChat] = useState("");
  const [file, setFile] = useState("");

  useEffect(() => {
    if (id) {
      dispatch(getChats(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    window.setTimeout(function () {
      var elem = document.getElementById("read-chat");
      elem.scrollTop = elem.scrollHeight;
    }, 1000);
    if (id) {
      dispatch(updateReadCount(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (location) {
      setUser(location.state.data);
      var value = location.state.data;
      if (value) {
        let name = value;
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
        let initials = [...name.matchAll(rgx)] || [];
        initials = (
          (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
        ).toUpperCase();
        setInitial(initials);
      } else {
        setInitial("");
      }
    }
  }, [location]);

  useEffect(() => {
    if (isAddSuccess || isEditSuccess || isDeleteSuccess) {
      dispatch(getChats(id));
    }
    // eslint-disable-next-line
  }, [isAddSuccess, isEditSuccess, isDeleteSuccess]);

  const dateFormat = (date) => {
    if (date === null) {
      return "-";
    }
    const options = { year: "numeric", month: "short", day: "numeric" };
    const inputDate = new Date(date).toLocaleDateString("en", options);
    return inputDate;
  };
  const ref = React.useRef();
  const sendComment = () => {
    if (chat || file) {
      const obj = {
        comments: chat,
        receiver_id: Number(id),
        file: file,
      };
      dispatch(addChats(obj));
      setChat("");
      setFile("");
      ref.current.value = ""
    }
  };

  const toggleHidden = (index, item) => {
    setHidden({ ...hidden, [index]: !hidden[index] });
    setChat("");
    setEditChat(item);
  };

  const submitEditComment = (id, index) => {
    const obj = {
      id: id,
      comments: editChat,
    };
    dispatch(editChats(obj));
    setHidden({ ...hidden, [index]: !hidden[index] });
  };

  const deleteComment = (id) => {
    dispatch(deleteChats(id));
  };

  const handleFile = (e) => {
    if (e.target.files[0]) {
      // console.log(e.target.files);
      setFile(e.target.files[0]);
    }
  };

  return (
    <div className="outerPage">
      <div>
        <h4>Chats</h4>
      </div>
      <div className="innerPage row p-2 d-flex justify-content-center chat">
        <div
          className="col-md"
          style={{
            background: "#eceff3",
            borderRadius: "3px",
            // display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <div className="read-chat" id="read-chat">
            {chats &&
              chats.map((item, i) => (
                <>
                  <div
                    key={i}
                    className="d-flex align-items-center"
                    style={
                      item.sender_id === 0
                        ? { flexDirection: "row-reverse" }
                        : {}
                    }
                  >
                    <div className="client-icon">
                      {item.sender_id === 0 ? "S" : initials}
                    </div>
                    <div key={i} className="in-chat p-2 m-3">
                      <div className="d-flex justify-content-between">
                        <div className="sm-text">
                          {item.sender_id === 0 ? "Support" : user}
                        </div>
                        <div className="me-2 sm-text d-flex align-items-center">
                          <div className="me-2">
                            {dateFormat(item.createdAt)}
                          </div>
                          {item.sender_id === 0 && (
                            <div style={{ fontSize: 16 }}>
                              <EditIcon
                                fontSize="inherut"
                                color="#7c828d"
                                className="me-2"
                                onClick={(e) => {
                                  toggleHidden(i, item.comments);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <DeleteIcon
                                fontSize="inherit"
                                color="#e04f44"
                                className="me-2"
                                onClick={() => deleteComment(item.id)}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {!hidden[i] && (
                        <div className="comment-text">
                          {item.comments} &nbsp;
                          {item.files ? (
                            <a
                              href={
                                "https://s3.amazonaws.com/taskforc2.0/" +
                                item.files
                              }
                              // target="_blank"
                              download
                              rel="noreferrer"
                            >
                              {item.files}
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {!!hidden[i] && (
                        <>
                          <input
                            type="text"
                            value={editChat}
                            onChange={(e) => setEditChat(e.target.value)}
                            className="form-control"
                          />
                          <div className="text-end mt-3">
                            <button
                              type="button"
                              className="cancel-button me-2"
                              onClick={() => {
                                setHidden({
                                  ...hidden,
                                  [i]: !hidden[i],
                                });
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="apply-button"
                              onClick={() => submitEditComment(item.id, i)}
                            >
                              Save
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ))}
          </div>

          {/* send chat */}
          <div className="d-flex chat-input align-items-center">
            <div
              style={{ width: "90%" }}
              className="send-comment d-flex align-items-center"
            >
              <input
                className="comment-input"
                type="text"
                placeholder="Chat here..."
                onChange={(e) => setChat(e.target.value)}
                value={chat}
              />
              <div className="ms-3 d-flex">
                <label htmlFor="file">
                  <AttachFileIcon />
                </label>
                <input type="file" id="file" onChange={handleFile} ref={ref}/>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="send"
            >
              <button
                type="button"
                className="btn"
                style={{ display: "flex", alignItems: "center" }}
                onClick={sendComment}
              >
                <SendIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

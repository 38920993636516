import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { login, logout } from "../../Redux/Actions/loginActions";
import { alertActions } from "../../Redux/Actions/alertActions";
import { roleId } from "../Common/Constants";
import AlertModal from "../AlertModal/AlertModal";
import { emailErrors, loginError, passwordErrors } from "../Common/Constants";

import styles from "./Login.module.css";

function Login() {
  //state management
  const [showPassword, setShowPassword] = useState(false);

  //form initial values
  const initialValues = {
    email: "",
    password: "",
  };

  // Used to change the routes:
  const Navigator = useNavigate();

  //Redux Dispatch
  const dispatch = useDispatch();

  // Redux State:
  const LoginDetails = useSelector((state) => state.loginReducer);
  const alert = useSelector((state) => state.alert);

  // UseEffects (start):
  useEffect(() => {
    if (LoginDetails.isAuthenticated) {
      if (LoginDetails.role_id === roleId.SUPER_ADMIN_USER) {
        return Navigator("/dashboard");
      } else {
        dispatch(logout);
        dispatch(alertActions.error(loginError.INVALID_ROLE));
        setTimeout(() => {
          dispatch(alertActions.error_clear());
          dispatch(alertActions.clear());
        }, 3000);
      }
    }
  }, [LoginDetails.isAuthenticated, dispatch, LoginDetails.role_id, Navigator]);
  // UseEffects (end):

  //toggle password function
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  //login post api call
  const loginHandler = (values) => {
    // console.log(values);
    dispatch(login(values));
  };

  // Validations (start):
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = emailErrors.EMAIL;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      //eslint-disable-line
      errors.email = emailErrors.INVALID_EMAIL;
    }
    if (!values.password) {
      errors.password = passwordErrors.PASSWORD;
    }
    // else if (
    //   !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/.test(
    //     //eslint-disable-line
    //     values.password
    //   )
    // )
    //  {
    //   errors.password = passwordErrors.PASSWORD_INVALID
    // }
    return errors;
  };
  // Validations (end):

  return (
    <>
      <h3 className={`${styles.brandName}`}>Multi tier</h3>
      <div className="login">
        <div className={`col-lg-4 col-md-6 col-sm-10 ${styles.loginBox}`}>
          <div className={`text-center w-100 pt-4`}>
            <h2 className={`primaryText ${styles.headingText}`}>
              Welcome back!
            </h2>
            {/* <p>Do not have an account? <button type="button" className={`${styles.linkText}`} >Sign Up</button></p> */}
          </div>

          {alert.message && <AlertModal show={true} />}
          <div className={`mx-2 mt-2 ${styles.formSection}`}>
            <Formik
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              validate={(values) => validate(values)}
              onSubmit={(values) => {
                loginHandler(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="form-group my-4">
                    <div className={`input-group py-3`}>
                      <span className={`input-group-addon ${styles.fieldIcon}`}>
                        <MailOutlineIcon />
                      </span>
                      <Field
                        name="email"
                        type="text"
                        placeholder="Enter your email address"
                        className={
                          touched.email && errors.email
                            ? `${styles.textField}  ${styles.textFieldError}`
                            : `${styles.textField}`
                        }
                      />
                      {touched.email && errors.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className={`input-group py-3`}>
                      <span className={`input-group-addon ${styles.fieldIcon}`}>
                        <HttpsOutlinedIcon />
                      </span>
                      <Field
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        className={
                          touched.password && errors.password
                            ? `${styles.textField}  ${styles.textFieldError}`
                            : `${styles.textField}`
                        }
                      />
                      <span className={` ${styles.pwdIcon}`}>
                        <button
                          type="button"
                          className={`btn ${styles.pwdButton}`}
                          onClick={togglePassword}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </button>
                      </span>
                      {touched.password && errors.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                    </div>
                    {/* <div className="text-end">
                      <button type="button" className={`${styles.linkText}`}>
                        forgot password?
                      </button>
                    </div> */}
                  </div>
                  <div className="form-group pt-2">
                    <button
                      className={`w-100 ${styles.submitButton}`}
                      type="submit"
                      disabled={LoginDetails.loading}
                    >
                      <span className="me-3">Sign In</span>
                      {LoginDetails.loading && (
                        <span
                          className="spinner-border spinner-border align-middle"
                          role="status"
                        ></span>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="login-bg"></div>
    </>
  );
}

export default Login;

import axios from "axios";
import api from "../Api/Api";
import {
  GET_CHAT_FAIL,
  GET_CHAT_START,
  GET_CHAT_SUCCESS,
  ADD_CHAT_FAIL,
  ADD_CHAT_START,
  ADD_CHAT_SUCCESS,
  EDIT_CHAT_FAIL,
  EDIT_CHAT_START,
  EDIT_CHAT_SUCCESS,
  DELETE_CHAT_FAIL,
  DELETE_CHAT_START,
  DELETE_CHAT_SUCCESS,
  READ_COUNT_CHAT_START,
  READ_COUNT_CHAT_SUCCESS,
  READ_COUNT_CHAT_FAIL,
  UPDATE_COUNT_CHAT_START,
  UPDATE_COUNT_CHAT_SUCCESS,
  UPDATE_COUNT_CHAT_FAIL,
} from "./Types";
import { API_baseAppURL } from "../Api/RequestPath";

//GET Chats
export const getChats = (id) => async (dispatch) => {
  dispatch({
    type: GET_CHAT_START,
  });
  try {
    const res = await api.get("/chat/getChat/" + id);
    if (res) {
      dispatch({
        type: GET_CHAT_SUCCESS,
        payload: res.data && res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CHAT_FAIL,
      payload: err.response && err.response,
    });
  }
};

//ADD Chats
export const addChats = (data) => async (dispatch) => {
  dispatch({
    type: ADD_CHAT_START,
  });
  try {
    // const res = await api.post("/chat/postChat", data);
    // if (res) {
    //   dispatch({
    //     type: ADD_CHAT_SUCCESS,
    //     payload: res.data && res.data,
    //   });
    // }
    let formData = new FormData();
    formData.append("comments", data.comments);
    formData.append("file", data.file);
    formData.append("receiver_id", data.receiver_id);
    // console.log(formData.get("file"), formData.get("comments"));
    axios
      .post(API_baseAppURL + "chat/postChat", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({
          type: ADD_CHAT_SUCCESS,
          payload: res.data && res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: ADD_CHAT_FAIL,
          payload: err.response && err.response,
        });
      });
  } catch (err) {
    dispatch({
      type: ADD_CHAT_FAIL,
      payload: err.response && err.response,
    });
  }
};

//EDIT Chats
export const editChats = (data) => async (dispatch) => {
  dispatch({
    type: EDIT_CHAT_START,
  });
  try {
    const res = await api.put("/chat/editChat/" + data.id, data);
    if (res) {
      dispatch({
        type: EDIT_CHAT_SUCCESS,
        payload: res.data && res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EDIT_CHAT_FAIL,
      payload: err.response && err.response,
    });
  }
};

//DELETE Chats
export const deleteChats = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_CHAT_START,
  });
  try {
    const res = await api.delete("/chat/deleteChat/" + id);
    if (res) {
      dispatch({
        type: DELETE_CHAT_SUCCESS,
        payload: res.data && res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DELETE_CHAT_FAIL,
      payload: err.response && err.response,
    });
  }
};

//get read count
export const getReadCount = (id) => async (dispatch) => {
  dispatch({
    type: READ_COUNT_CHAT_START,
  });
  try {
    const res = await api.get("/chat/getReadCount/" + id);
    if (res) {
      dispatch({
        type: READ_COUNT_CHAT_SUCCESS,
        payload: res.data && res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: READ_COUNT_CHAT_FAIL,
      payload: err.response && err.response,
    });
  }
};

//update read count
export const updateReadCount = (id) => async (dispatch) => {
  dispatch({
    type: UPDATE_COUNT_CHAT_START,
  });
  try {
    const res = await api.put("/chat/updateReadStatus/" + id);
    if (res) {
      dispatch({
        type: UPDATE_COUNT_CHAT_SUCCESS,
        payload: res.data && res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: UPDATE_COUNT_CHAT_FAIL,
      payload: err.response && err.response,
    });
  }
};

/* eslint-disable import/no-anonymous-default-export */
import { GET_SUBSCRIPTIONS_START, GET_SUBSCRIPTIONS_SUCCESS, GET_SUBSCRIPTIONS_FAIL } from "../Actions/Types";
 
 const initialState = {
     response: '',
     error: '',
     getSubscriptionsLoading: false,
   };
   
   export default function (state = initialState, action) {
     const { type, payload } = action;
     switch (type) {
         case GET_SUBSCRIPTIONS_START:
           return {
             ...state,
             getSubscriptionsLoading: true
           };
         case GET_SUBSCRIPTIONS_SUCCESS:
         return {
           ...state,
           response: payload,
           getSubscriptionsLoading: false
         };
         case GET_SUBSCRIPTIONS_FAIL:
         return {
           ...state,
           error: payload,
           getSubscriptionsLoading: false
         }; 
         default:
           return state;
     }
 }
import api from "../Api/Api";
import {
  GET_ALL_BUSINESSES_START,
  GET_ALL_BUSINESSES_SUCCESS,
  GET_ALL_BUSINESSES_FAIL,
  EDIT_STATUS_START,
  EDIT_STATUS_SUCCESS,
  EDIT_STATUS_FAIL,
  EDIT_BUSINESS_START,
  EDIT_BUSINESS_SUCCESS,
  EDIT_BUSINESS_FAIL,
  ADD_BUSINESS_START,
  ADD_BUSINESS_SUCCESS,
  ADD_BUSINESS_FAIL,
  DELETE_BUSINESS_START,
  DELETE_BUSINESS_SUCCESS,
  DELETE_BUSINESS_FAIL,
  UPDATE_PLAN_CHANGE_START,
  UPDATE_PLAN_CHANGE_SUCCESS,
  UPDATE_PLAN_CHANGE_FAIL,
  DASHBOARD_COUNT_START,
  DASHBOARD_COUNT_SUCCESS,
  DASHBOARD_COUNT_FAIL
} from "./Types";
import { alertActions } from "./alertActions";

// GET BUSINESSES
export const getBusinesses = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_BUSINESSES_START,
  });
  try {
    const res = await api.get("/business/getBusiness");
    if (res) {
      dispatch({
        type: GET_ALL_BUSINESSES_SUCCESS,
        payload: res.data && res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_BUSINESSES_FAIL,
      payload: err.response && err.response,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

// edit status
export const editStatus = (id, Data) => async (dispatch) => {
  dispatch({
    type: EDIT_STATUS_START,
  });
  try {
    const res = await api.put("/business/editStatus/" + id, Data);
    if (res) {
      dispatch({
        type: EDIT_STATUS_SUCCESS,
        payload: res.data && res.data,
      });
      dispatch(alertActions.success(res.data.msg));
      setTimeout(() => {
        dispatch(alertActions.success_clear());
        dispatch(alertActions.clear());
      }, 3000);
    }
  } catch (err) {
    dispatch({
      type: EDIT_STATUS_FAIL,
      payload: err.response && err.response,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

//EDIT BUSINESS
export const editBusiness = (id, data) => async (dispatch) => {
  dispatch({
    type: EDIT_BUSINESS_START,
  });
  try {
    const res = await api.put("/business/editBusiness/" + id, data);
    if (res) {
      dispatch({
        type: EDIT_BUSINESS_SUCCESS,
        payload: res.data && res.data,
      });
      dispatch(alertActions.success(res.data.msg));
      setTimeout(() => {
        dispatch(alertActions.success_clear());
        dispatch(alertActions.clear());
      }, 3000);
    }
  } catch (err) {
    dispatch({
      type: EDIT_BUSINESS_FAIL,
      payload: err.response && err.response,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

//ADD BUSINESS
export const addBusiness = (data) => async (dispatch) => {
  dispatch({
    type: ADD_BUSINESS_START,
  });
  try {
    const res = await api.post("/business/addBusiness", data);
    if (res) {
      dispatch({
        type: ADD_BUSINESS_SUCCESS,
        payload: res.data && res.data,
      });
      dispatch(alertActions.success(res.data.msg));
      setTimeout(() => {
        dispatch(alertActions.success_clear());
        dispatch(alertActions.clear());
      }, 3000);
    }
  } catch (err) {
    dispatch({
      type: ADD_BUSINESS_FAIL,
      payload: err.response && err.response,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

//DELETE BUSINESS
export const deleteBusiness = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_BUSINESS_START,
  });
  try {
    const res = await api.put("/business/deleteBusiness/" + id);
    if (res) {
      dispatch({
        type: DELETE_BUSINESS_SUCCESS,
        payload: res.data && res.data,
      });
      dispatch(alertActions.success(res.data.msg));
      setTimeout(() => {
        dispatch(alertActions.success_clear());
        dispatch(alertActions.clear());
      }, 3000);
    }
  } catch (err) {
    dispatch({
      type: DELETE_BUSINESS_FAIL,
      payload: err.response && err.response,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

//UPDATE BUSINESS PACKAGE
export const editBusinessPackage = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_PLAN_CHANGE_START,
  });
  try {
    const obj = {
      package_id: data.package_id,
      plan: data.plan,
      transaction_id : data.transaction_id
    };
    const res = await api.put("/business/editBusinessPackage/" + data.id, obj);
    if (res) {
      dispatch({
        type: UPDATE_PLAN_CHANGE_SUCCESS,
        payload: res.data && res.data,
      });
      dispatch(alertActions.success(res.data.msg));
      setTimeout(() => {
        dispatch(alertActions.success_clear());
        dispatch(alertActions.clear());
      }, 3000);
    }
  } catch (err) {
    console.log("error",err);
    dispatch({
      type: UPDATE_PLAN_CHANGE_FAIL,
      payload: err.response && err.response,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

// GET DASHBOARD COUNT
export const getDashboardCount= (data) => async (dispatch) => {
  dispatch({
    type: DASHBOARD_COUNT_START,
  });
  try {
    const res = await api.get("/dashboard/getCount/"+ data);
    if (res) {
      dispatch({
        type: DASHBOARD_COUNT_SUCCESS,
        payload: res.data && res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DASHBOARD_COUNT_FAIL,
      payload: err.response && err.response,
    });
    dispatch(alertActions.error(err.response.data.msg));
    setTimeout(() => {
      dispatch(alertActions.error_clear());
      dispatch(alertActions.clear());
    }, 3000);
  }
};

import React from "react";
import "./App.css";
import { HashRouter } from "react-router-dom";
import BaseRoutes from "./Routing/routes";
import { Provider } from "react-redux";
import store from "./Redux/Store/Store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import setAuthToken from "./Utils/setAuthToken";

//setting auth token in headers
if (localStorage.token) {
  // console.log(localStorage.token);
  setAuthToken(localStorage.token);
}

function App() {
  return (
    <Provider store={store}>
      <div>
        <HashRouter>
          <BaseRoutes />
        </HashRouter>
      </div>
    </Provider>
  );
}

export default App;

import api from "../Api/Api";
import { GET_TRANSACTIONS_START, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_FAIL } from "./Types";
import { alertActions } from "./alertActions";

//GET TRANSACTIONS
export const getTransactions = () => async (dispatch) => {
    dispatch({
      type: GET_TRANSACTIONS_START,
    });
try {
  const res = await api.get("/transactions/getTransactions");
  if (res) {
    dispatch({
      type: GET_TRANSACTIONS_SUCCESS,
      payload: res.data && res.data,
    });
  }
} catch (err) {
  dispatch({
    type: GET_TRANSACTIONS_FAIL,
    payload: err.response && err.response,
  });
  dispatch(alertActions.error(err.response.data.msg));
  setTimeout(() => {
    dispatch(alertActions.error_clear());
    dispatch(alertActions.clear());
  }, 3000);
}
};

// export const API_baseAppURL = "http://localhost:3003/api/";
// export const checkout_url = "http://localhost:3003/";

//QA
// export const API_baseAppURL = 'https://multi-tier-backend.onrender.com/api/';
//export const checkout_url = "https://multi-tier-backend.onrender.com/"

//LIVE

export const API_baseAppURL = 'https://superadminapi.taskforc.com/api/';
export const checkout_url = "https://superadminapi.taskforc.com/"
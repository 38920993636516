/* eslint-disable import/no-anonymous-default-export */
import {
  ADD_CHAT_START,
  ADD_CHAT_SUCCESS,
  ADD_CHAT_FAIL,
  GET_CHAT_START,
  GET_CHAT_SUCCESS,
  GET_CHAT_FAIL,
  EDIT_CHAT_START,
  EDIT_CHAT_SUCCESS,
  EDIT_CHAT_FAIL,
  DELETE_CHAT_START,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT_FAIL,
  READ_COUNT_CHAT_START,
  READ_COUNT_CHAT_SUCCESS,
  READ_COUNT_CHAT_FAIL,
  UPDATE_COUNT_CHAT_START,
  UPDATE_COUNT_CHAT_SUCCESS,
  UPDATE_COUNT_CHAT_FAIL,
} from "../Actions/Types";

const initialState = {
  isAddSuccess: "",
  isEditSuccess: "",
  isDeleteSuccess: "",
  chats: [],
  readCount: [],
  isUpdateReadCount: "",
  error: "",
  isLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_CHAT_START:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_CHAT_SUCCESS:
      return {
        ...state,
        isAddSuccess: payload,
        isLoading: false,
      };
    case ADD_CHAT_FAIL:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case GET_CHAT_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CHAT_SUCCESS:
      return {
        ...state,
        chats: payload,
        isLoading: false,
      };
    case GET_CHAT_FAIL:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case EDIT_CHAT_START:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_CHAT_SUCCESS:
      return {
        ...state,
        isEditSuccess: payload,
        isLoading: false,
      };
    case EDIT_CHAT_FAIL:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case DELETE_CHAT_START:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_CHAT_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: payload,
        isLoading: false,
      };
    case DELETE_CHAT_FAIL:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case READ_COUNT_CHAT_START:
      return {
        ...state,
        isLoading: true,
      };
    case READ_COUNT_CHAT_SUCCESS:
      return {
        ...state,
        readCount: payload,
        isLoading: false,
      };
    case READ_COUNT_CHAT_FAIL:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case UPDATE_COUNT_CHAT_START:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_COUNT_CHAT_SUCCESS:
      return {
        ...state,
        isUpdateReadCount: payload,
        isLoading: false,
      };
    case UPDATE_COUNT_CHAT_FAIL:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
